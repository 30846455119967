import React, { memo } from 'react';
import './index.scss'
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { MR_LOGO, CRITIC_FULL_LOGO } from '../../assets/logos';
import SectionTitle from '../../shared/components/SectionTitle';

const About = () => {
    return (
        <Row className="mb-2">
            <Col xs="12" lg="6" className="d-flex flex-column">
                <SectionTitle title="Sobre o aplicativo" />
                <Card className="mb- h-100">
                    <Card.Body className="text-center">
                        <img className="img-app" alt="" src={CRITIC_FULL_LOGO}></img>
                        <br />
                        <div className="mt-2 text-left">
                            O MR Critic tem como objetivo auxiliar na prática médica ao facilitar o cálculo das drogas de uso em sedação e analgesia contínua, drogas vasoativas, bem como da indução para a IOT. Além disso, traz consigo resumos a respeito do uso desses fármacos, sua indicação, modo de ação, além de resumos sobre marcadores hemodinâmicos e sobre a ventilação mecânica. Uma ferramenta fundamental ao médico/estudante que esteja atuando/estagiando em unidade de terapia intensiva.
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12" lg="6" className="d-flex flex-column">
                <SectionTitle title="Sobre o criador" />
                <Card className="h-100">
                    <Card.Body className="text-center">
                        <img alt="" height="100" width="100" src={MR_LOGO}></img>
                        <div className="text-left">
                            O <strong>Medicina Resumida</strong> é um grupo criado em 2014 com o intuito de auxiliar o estudante e o médico no seu dia a dia, trazendo conteúdo de qualidade com uma abordagem inovadora e prática. <a className="mr-tree-light" rel="noopener noreferrer" target="_blank" href="https://medicinaresumida.com.br">Conheça nossas iniciativas.</a>
                        </div>
                        <br />
                        <div>
                            Conheça nossas redes sociais:
                        </div>
                        <div>
                            <Button variant={undefined} className="btn-social-network-light" onClick={() => window.open("https://youtube.com/user/medicinaresumida")}>
                                <FontAwesomeIcon className="icon-social-network-light" size={'2x'} icon={faYoutube} />
                            </Button>
                            <Button variant={undefined} className="btn-social-network-light" onClick={() => window.open("https://instagram.com/medresumida")}>
                                <FontAwesomeIcon className="icon-social-network-light" size={'2x'} icon={faInstagram} />
                            </Button>
                            <Button variant={undefined} className="btn-social-network-light" onClick={() => window.open("https://www.facebook.com/medicinaresumida/")}>
                                <FontAwesomeIcon className="icon-social-network-light" size={'2x'} icon={faFacebook} />
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default memo(About);
