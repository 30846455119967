export const USER_PREFS_LANG_UPDATE = "USER/PREFS_LANG_UPDATE";
export const USER_PREFS_IPHONE_ALERT_UPDATE = "USER/PREFS_IPHONE_ALERT_UPDATE";
export const USER_PREFS_MINIMIZED_SCROLL_TIP = "USER/PREFS_MINIMIZED_SCROLL_TIP";

export const updatePrefsLang = (lang: string) => ({
    type: USER_PREFS_LANG_UPDATE,
    payload: {
        lang
    }
});

export const updatePrefsIphoneAlert = (iphoneAlertShortcut: boolean) => ({
    type: USER_PREFS_IPHONE_ALERT_UPDATE,
    payload: {
        iphoneAlertShortcut
    }
});

export const updatePrefsMinimizedScrollTip = (minimizedScrollTip: boolean) => ({
    type: USER_PREFS_MINIMIZED_SCROLL_TIP,
    payload: {
        minimizedScrollTip
    }
});