import React from "react";
import { CRITIC_LOGO } from '../../../assets/logos';
import { memo } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const AppNavbar = () => {
    return (
        <Navbar collapseOnSelect bg="dark" variant="dark" sticky="top" expand="lg">
        <LinkContainer to="/">
          <Navbar.Brand>
            <img

              alt=""
              src={CRITIC_LOGO}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            {' '}
          MR Critic

        </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer exact to="/">
              <Nav.Link>Início</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/app">
              <Nav.Link>App</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link>Contato</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about">
              <Nav.Link>Sobre</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
}

export default memo(AppNavbar);