import React, { useEffect, useState, useCallback, useMemo } from 'react'
import "./index.scss"

import { useHistory } from 'react-router-dom'
import { Row, Col, Card, Form, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DrugInfoCard from '../DrugInfoCard';
import InputSpinner from 'react-bootstrap-input-spinner';
import { references } from "../../../../config/data/rapidSequence";
import theme from "../../../../config/theme";
import { getTotalDose, getTotalFlowRate } from '../../services/calculator/vasoactive';

const Vasoactive = (props: any) => {
    let history = useHistory();
    const [drug] = useState(props.drug);

    // States
    const [weight, setweight] = useState<number | undefined>(80);
    const [dose, setDose] = useState(props.drug?.doseMax);
    const [concentration, setConcentration] = useState(props.drug?.concentrations[0]);
    const [multiplier, setMultiplier] = useState(props.drug?.concentrations[0].multipliers[0]);
    const [mode, setMode] = useState(1);
    const [flow, setFlow] = useState(10);

    // Callbacks
    const setDoseCallBack = useCallback(value => setDose(value), [setDose]);
    const setFlowCallBack = useCallback(value => setFlow(value), [setFlow]);
    const setWeightCallBack = useCallback(
        values => setweight(values.floatValue === undefined ? undefined : Number(values.floatValue)),
        [setweight]
    );
    const setConcetrationCallBack = useCallback(
        event => setConcentration(drug?.concentrations?.find((c: any) => c.id === Number(event.target.value))),
        [drug, setConcentration]
    );
    const setMultiplierCallBack = useCallback(event => setMultiplier(event.target.value), [setMultiplier]);
    const setModeCallback = useCallback(value => setMode(value), [setMode]);

    // Effects
    useEffect(() => {
        if (!props.drug) {
            history.push("/");
        }
    });

    useEffect(() => {
        setMultiplier(concentration?.multipliers[0]);
    }, [concentration])

    // Memos
    const doseIntervalText = useMemo(
        () => <Form.Label>Dose ({drug?.doseMin + " - " + drug?.doseMax + drug?.doseUnit + (drug?.useWeight ? '/kg' : '') + '/' + drug?.timeUnit})</Form.Label>,
        [drug]
    );
    const flowRateText = useMemo(
        () => <Form.Label>Vazão em {drug?.volumeUnit + "/h"}:</Form.Label>,
        [drug]
    );

    const getDose = useMemo(
        () => <div id="dose-flow" className="dose-flow">
            {
                Number(
                    getTotalDose(
                        flow,
                        (drug.useWeight ? weight! : 1),
                        (drug.timeUnit === 'min' ? 60 : 1),
                        concentration.ampouleDose,
                        concentration.ampouleVolume,
                        concentration.ampouleDosePerMl,
                        concentration.solutionVolume,
                        multiplier
                    ).toFixed(2)
                )
            }
            {drug.doseUnit + (drug.useWeight ? '/kg' : '') + '/' + drug.timeUnit}
        </div>,
        [drug, flow, concentration, multiplier, weight]
    );

    const getFlowRate = useMemo(
        () => <div id="dose-result" className="flow-dose">
            {
                Number(
                    getTotalFlowRate(
                        dose,
                        (drug.useWeight ? weight! : 1),
                        (drug.timeUnit === 'min' ? 60 : 1),
                        concentration.ampouleDose,
                        concentration.ampouleVolume,
                        concentration.ampouleDosePerMl,
                        concentration.solutionVolume,
                        multiplier
                    ).toFixed(2)
                )
            }
            {drug.volumeUnit + '/h'}
        </div>,
        [drug, dose, concentration, multiplier, weight]
    );

    //Other functions
    const getDilution = () => {
        let concent = drug.concentrations.find((c: any) => c.id === concentration.id);
        return drug.concentrationTextTemplate.replace('@ampoules', multiplier).replace('@concentration', concent.label)
            .replace('@volume', (concent.subtractAmpoule ? (concent.solutionVolume - (concent.ampouleVolume * multiplier)) : concent.solutionVolume));
    }

    return (
        <>
            <Row>
                <Col xs={12} className="pl-1 pr-1 pb-2">
                    <Card >
                        <Card.Body >
                            <div className="type-container">
                                <FontAwesomeIcon icon="chevron-right" className="type-icon" />
                                <span id="page-title-subtitle" className="type-text">
                                    {drug?.type}
                                </span>
                            </div>
                            <Form>
                                <Form.Row>
                                    <Form.Group sm={12} lg={drug?.useWeight ? 3 : 3} as={Col} controlId="formMode">
                                        <Form.Label className="d-none d-md-block">{'Modo'}</Form.Label>
                                        <div>
                                            <ToggleButtonGroup name="radio" type="radio" className="d-flex" value={mode} onChange={setModeCallback}>
                                                <ToggleButton id="toggle-mode-1" className="w-100" variant={mode === 1 ? "dark" : "secondary"} value={1}>
                                                    Dose <FontAwesomeIcon icon="arrow-right" className="toggle-icon" /> Vazão
                                                </ToggleButton>
                                                <ToggleButton id="toggle-mode-2" className="w-100" variant={mode === 2 ? "dark" : "secondary"} value={2}>
                                                    Vazão <FontAwesomeIcon icon="arrow-right" className="toggle-icon" /> Dose
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </Form.Group>
                                    <Form.Group sm={12} lg={drug?.useWeight ? 3 : 3} as={Col} controlId="formDose">
                                        {
                                            drug !== undefined && mode === 1 &&
                                            <>
                                                {doseIntervalText}
                                                <InputSpinner
                                                    type={'real'}
                                                    variant={'dark'}
                                                    precision={2}
                                                    max={drug?.doseMax}
                                                    min={drug?.doseMin}
                                                    step={drug?.doseStep}
                                                    value={dose}
                                                    onChange={setDoseCallBack}
                                                />
                                            </>
                                        }
                                        {
                                            drug !== undefined && mode === 2 &&
                                            <>
                                                {flowRateText}
                                                <InputSpinner
                                                    type={'int'}
                                                    variant={'dark'}
                                                    precision={0}
                                                    max={Number.MAX_SAFE_INTEGER}
                                                    min={1}
                                                    step={1}
                                                    value={flow}
                                                    onChange={setFlowCallBack}
                                                />
                                            </>
                                        }
                                    </Form.Group>
                                    <Form.Group sm={12} lg={drug?.useWeight ? 2 : 3} as={Col} controlId="formConcentration">
                                        <Form.Label>Concentração</Form.Label>
                                        {
                                            drug?.concentrations?.length > 1 &&
                                            <Form.Control as="select" value={concentration.id} onChange={setConcetrationCallBack}>
                                                {
                                                    drug?.concentrations.map((concent: any) =>
                                                        <option key={concent.id} value={concent.id}>{concent.label}</option>
                                                    )
                                                }
                                            </Form.Control>
                                        }
                                        {
                                            drug?.concentrations?.length === 1 &&
                                            <Form.Control disabled defaultValue={drug?.concentrations.find((c: any) => c.id === concentration?.id)?.label} />
                                        }
                                    </Form.Group>
                                    <Form.Group sm={12} lg={drug?.useWeight ? 2 : 3} as={Col} controlId="formMultiplier">
                                        <Form.Label>Ampolas</Form.Label>
                                        {
                                            drug?.concentrations?.find((c: any) => c.id === concentration?.id)?.multipliers?.length > 1 &&
                                            <Form.Control as="select" value={multiplier} onChange={setMultiplierCallBack}>
                                                {
                                                    drug?.concentrations?.find((c: any) => c.id === concentration?.id)?.multipliers?.map((mult: any) =>
                                                        <option key={mult} value={mult}>{mult}</option>
                                                    )
                                                }
                                            </Form.Control>
                                        }
                                        {
                                            drug?.concentrations?.find((c: any) => c.id === concentration?.id)?.multipliers?.length === 1 &&
                                            <Form.Control disabled value={drug?.concentrations.find((c: any) => c.id === concentration?.id).multipliers[0]} />
                                        }
                                    </Form.Group>
                                    {
                                        drug?.useWeight &&
                                        <Form.Group sm={12} lg={2} as={Col} controlId="formWeight">
                                            <Form.Label>Peso (Kg)</Form.Label>
                                            <NumberFormat className="form-control" format="###" value={weight} onValueChange={setWeightCallBack} />
                                        </Form.Group>
                                    }
                                </Form.Row>
                            </Form>
                            {
                                /* Resultado Dose (vazão->dose) */
                                mode === 2 && ((weight !== undefined && weight !== 0) || !drug.useWeight) && flow !== undefined && flow > 0 &&
                                <Row>
                                    <Col className="text-center">
                                        <Card style={{ alignItems: 'center', borderWidth: 1, borderColor: theme.secondary }}>
                                            <Card.Body>
                                                <span color={theme.secondary} className="h6">Dose:</span>
                                                {getDose}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            {
                                /* Resultado Vazão (dose->vazão)*/
                                mode === 1 && ((weight !== undefined && weight !== 0) || !drug.useWeight) && dose !== undefined && dose >= drug.doseMin && dose <= drug.doseMax &&
                                <Row>
                                    <Col className="text-center">
                                        <Card style={{ alignItems: 'center', borderWidth: 1, borderColor: theme.secondary }}>
                                            <Card.Body>
                                                <span color={theme.secondary} className="h6">Vazão:</span>
                                                {/* <div id="dose-result" style={{ color: theme.primary, fontSize: 25, fontWeight: "bold" }}>{Number(getFlowRate().toFixed(2))}{drug.volumeUnit + '/h'}</div> */}
                                                {getFlowRate}
                                                <div style={{ color: theme.primary, fontStyle: "italic", marginTop: 3 }}>
                                                    <FontAwesomeIcon icon="vial" color={theme.primary} />
                                                    {" "}
                                                    <span id="dose-dilution">{getDilution()}</span>
                                                </div>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* <Row>
                <Col xs={12} className="pl-1 pr-1 pb-2">
                    <Ad />
                </Col>
            </Row> */}
            <Row>
                {/* Propriedades */}
                {
                    drug?.presentations &&
                    <DrugInfoCard title={drug.presentations.length > 1 ? 'Apresentações' : 'Apresentação'} list={drug.presentations} iconName={'vial'} />
                }
                {
                    drug?.characteristics &&
                    <DrugInfoCard title={'Características'} list={drug.characteristics} iconName={'star'} />
                }
                {
                    drug?.usage &&
                    <DrugInfoCard title={'Uso'} list={drug.usage} iconName={'hand-paper'} />
                }
                {
                    drug?.effects && drug.effects.length > 0 &&
                    <DrugInfoCard title={'Efeitos'} list={drug.effects} iconName={'user-injured'} />
                }
                {
                    drug?.receivers && drug.receivers.length > 0 &&
                    <DrugInfoCard title={'Receptores'} list={drug.receivers} iconName={'clone'} />
                }
                {
                    drug?.indications && drug.indications.length > 0 &&
                    <DrugInfoCard title={drug.indications.length > 1 ? 'Indicações' : 'Indicação'} list={drug.indications} iconName={'check'} />
                }
                {
                    drug?.contraindications && drug.contraindications.length > 0 &&
                    <DrugInfoCard title={'Contraindicações'} list={drug.contraindications} iconName={'times'} />
                }
                {
                    references && references.length > 0 &&
                    <DrugInfoCard
                        title={references.length > 0 ? 'Referências' : 'Referência'} list={references} iconName={'star-of-life'} itemFontSize={11} />
                }
            </Row>
        </>
    )
}

export default Vasoactive
