import React, { useEffect } from 'react';

import firebase from 'firebase/app'
import "firebase/analytics"
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';

import HomePage from "./pages/home";
import AppPage from "./pages/app";
import AboutPage from "./pages/about";
import ContactPage from "./pages/contact"

import MVGuidance from './modules/intensiveCare/components/Guidance/MVGuidance';
import Calculators from "./pages/calculators/calculators";
import IdealWeight from "./modules/intensiveCare/components/idealWeight";
import SDRA from "./modules/intensiveCare/components/SDRA";

import HemodynamicGuidance from "./modules/intensiveCare/components/Guidance/HemodynamicGuidance";
import OTIGuidance from "./modules/intensiveCare/components/Guidance/OTIGuidance";

import Drugs from "./pages/drugs";
import Guidance from './pages/guidance'
import * as drugsData from "./config/data";
import config from './config/env';
import Drug from './pages/drug';
import DrugType from './modules/intensiveCare/models/DrugType';
import AppNavbar from './shared/components/AppNavbar';
import { useSelector } from 'react-redux';
import { RootState } from './store/reducer';

library.add(fas);

function App() {
  const userPrefs = useSelector((state: RootState) => state.user.preferences);

  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TARGET === 'production') {
      if (!firebase.apps.length) {
        let firebaseConfig = config.firebaseConfig;
        let firebaseApp = firebase.initializeApp(firebaseConfig);
        firebase.analytics(firebaseApp);
      }
    } else {
      console.log(process.env.REACT_APP_BUILD_TARGET);
    }
  }, []);

  useEffect(() => document.body.classList.add(`app-bg--${userPrefs.theme}`), [userPrefs.theme]);

  return (
    <>
      <AppNavbar />
      <Container className="mt-2">
        <Switch>
          {/* Index */}
          <Route exact path="/" component={HomePage} />

          {/* IOT */}
          <Route
            exact
            path="/orotracheal-intubation/guidance"
            render={() => <Guidance title="Intubação Orotraqueal"><OTIGuidance /></Guidance>}
          />
          <Route
            exact
            path="/orotracheal-intubation/drugs/"
            render={({ match }) => <Drugs
              title="Intubação Orotraqueal"
              subtitle="Drogas"
              drugs={drugsData.rapidSequence}
              path={match.path}
            />}
          />
          <Route exact path="/orotracheal-intubation/drugs/:drugKey"
            render={props => <Drug drugType={DrugType.RAPID_SEQUENCE} drugId={props.match.params.drugKey} />}
          />

          {/* VM */}
          <Route
            exact
            path="/mechanical-ventilation/guidance"
            render={() => <Guidance title="Ventilação mecânica"><MVGuidance /></Guidance>}
          />
          <Route exact path="/mechanical-ventilation/calculators" component={Calculators} />
          <Route exact path="/mechanical-ventilation/calculators/ideal-weight" component={IdealWeight} />
          <Route exact path="/mechanical-ventilation/calculators/sdra" component={SDRA} />
          <Route
            exact
            path="/mechanical-ventilation/drugs/"
            render={({ match }) => <Drugs
              title="Ventilação mecânica"
              subtitle="Drogas"
              drugs={drugsData.maintenance}
              path={match.path}
            />}
          />
          <Route exact path="/mechanical-ventilation/drugs/:drugKey"
            render={props => <Drug drugType={DrugType.MAINTENANCE} drugId={props.match.params.drugKey} />}
          />
          {/* Hemodinâmica */}
          <Route
            exact
            path="/hemodynamic/guidance"
            render={() => <Guidance title="Hemodinâmica"><HemodynamicGuidance /></Guidance>}
          />
          <Route
            exact
            path="/hemodynamic/drugs/"
            render={({ match }) => <Drugs
              title="Hemodinâmica"
              subtitle="Drogas"
              drugs={drugsData.vasoactive}
              path={match.path}
            />}
          />
          <Route exact path="/hemodynamic/drugs/:drugKey"
            render={props => <Drug drugType={DrugType.VASOACTIVE} drugId={props.match.params.drugKey} />}
          />

          <Route exact path="/app" component={AppPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/contact" component={ContactPage} />
          <Redirect exact from="*" to="/" />
        </Switch>
      </Container>
    </>
  );
}

export default App;
