import React, { memo } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";

const OTIGuidance = () => {
    return (
        <Card className="mb-2">
            <Card.Body>
                <p>
                    A intubação orotraqueal é um tipo de procedimento médico que visa garantir uma via aérea definitiva, ou seja, será necessário inserir um tubo na traqueia de um paciente, com o balonete (ou <i>cuff</i>) insuflado abaixo das cordas vocais, conectá-lo ao ventilador mecânico no sentido de fornecer oxigênio com volume, fluxo e pressão determinadas para a sua condição apresentada.
                    </p>
                <p>
                    A depender do que um paciente apresente de doença, o fluxo de oxigênio aos órgãos vitais (cérebro, coração, pulmões, rins, etc.) pode ser comprometido. Portanto, ele necessitará de uma entrega adequada de sangue oxigenado, essencial para manutenção da sua vida. Essa situação se aplica tanto para os doentes graves, em leitos de UTI ou no setor de emergência, ou pacientes menos graves, eletivos, tais como aqueles que estão sendo preparado para a realização de uma cirurgia. Uma via aérea protegida e segura é essencial para evitar hipoxemia e otimizar a recuperação do paciente.
                    </p>
                <p>
                    Existem três tipos de vias aéreas definitivas: tubo orotraqueal, tubo nasotraqueal e via aérea cirúrgica (cricotireoidostomia e traqueostomia), e o médico, de modo geral, precisa dominar tais procedimentos, estando capacitado tanto para indica-las quanto para executá-las.
                    </p>
                <h6 className="text-danger-light">Indicações da Intubação Orotraqueal</h6>
                <div>
                    <FontAwesomeIcon icon="play" size="sm" /> Na emergência, as indicações mais comuns para intubação orotraqueal são:
                        <ul>
                        <li>
                            Insuficiência respiratória aguda;
                            </li>
                        <li>
                            Oxigenação ou ventilação inadequadas e;
                            </li>
                        <li>
                            Proteção das vias aéreas em um paciente com rebaixamento do nível de consciência.
                            </li>
                    </ul>
                </div>
                <div>
                    <FontAwesomeIcon icon="play" size="sm" /> No contexto perioperatório, a IOT é necessária em:
                        <ul>
                        <li>Pacientes que serão submetidos a anestesia geral;</li>
                        <li>Cirurgia que envolve as vias aéreas ou regiões adjacentes;</li>
                        <li>Pacientes inconscientes que necessitam de proteção das vias aéreas;</li>
                        <li>Cirurgia envolvendo posicionamento incomum do paciente e;</li>
                        <li>Hiperventilação (situação menos frequente)</li>
                    </ul>
                </div>
                <div>
                    <FontAwesomeIcon icon="play" size="sm" /> Em cenários de trauma, a IOT é indicada quando há:
                        <ul>
                        <li>Incapacidade de manter uma via aérea patente por outros meios, com comprometimento iminente ou potencial (por exemplo, após lesão por inalação, fraturas faciais ou hematoma retrofaríngeo);</li>
                        <li>Incapacidade de manter a oxigenação adequada por suplementação de oxigênio com máscara facial, ou a presença de apneia;</li>
                        <li>Rebaixamento do nível de consciência ou combatividade resultante de hipoperfusão cerebral e;</li>
                        <li>Rebaixamento do nível de consciência, indicando a presença de um ferimento na cabeça e necessitando de ventilação assistida (pontuação na Escala de Coma de Glasgow menor ou igual a 8), atividade convulsiva sustentada e a necessidade de proteger a parte inferior vias aéreas da aspiração de sangue ou vômito.</li>
                    </ul>
                </div>
                <p>
                    Existem situações práticas nas quais a decisão de intubar é muito clara e não deve ser postergada, tais como no paciente comatoso e/ou traumatizado, pacientes com insuficiência respiratória aguda ou cardíaca que não está melhorando com medidas clínicas nem com ventilação não invasiva (VNI).
                    </p>
                <p>
                    No entanto, outras situações não tão claras ou óbvias requerem do médico o poder de levar com consideração vários fatores, antes de decidir se a intubação será necessária, como por exemplo: o estado respiratório do paciente, o processo patológico e a probabilidade de deterioração, a idade e as comorbidades do paciente, a necessidade de transferências e a disponibilidade de recursos.
                    </p>
                <div>
                    De forma prática, quando a decisão ou a necessidade de garantir uma via aérea definitiva (ou seja, intubar o paciente) não for imediatamente clara, o médico precisa fazer 3 perguntas a si mesmo:
                        <ul>
                        <li>A patência ou proteção das vias aéreas está em risco?</li>
                        <li>A oxigenação ou ventilação está falhando?</li>
                        <li>Há necessidade de intubação antecipada, ou seja, qual é o curso clínico esperado?</li>
                    </ul>
                </div>
                <p>
                    Se houver uma resposta positiva a qualquer uma dessas perguntas, identifica a necessidade de intubação em quase todos os cenários de emergência.
                    </p>
                <p>
                    Visto isso, você pode lançar mão da técnica de intubação por sequência rápida (SRI – sequência rápida de intubação), cujo objetivo é garantir uma via aérea definitiva e segura no menor tempo possível após a abolição dos reflexos protetores, por meio de drogas que serão necessárias nesse processo. Essa técnica foi desenvolvida com a intenção de aumentar a segurança dos pacientes considerados de risco para broncoaspiração do conteúdo gástrico, ou seja, é melhor indicada para um paciente com "estômago cheio".
                    </p>
                <p>
                    Do ponto de vista prático, a SRI é executada de forma sistematizada em sete etapas, classicamente conhecida como: os 7 P’s da Intubação Orotraqueal.
                    </p>
                <div>
                    <strong>1 - Preparação</strong>
                    <p>
                        Você deve garantir que a intubação do paciente ocorra sem erros de equipamentos, técnicos, além de prever se a intubação será fácil ou difícil. Esse passo deve durar poucos minutos e consiste em avaliar, fazer um checklist de material necessário, monitorizar o paciente, preparar as medicações e garantir um acesso venoso. Use o mnemônico <span className="text-danger-light">STOP-MAID</span> para lembras do que irá precisar nessa etapa.

                        </p>
                    <ul>
                        <li><span className="text-danger-light">S</span>: Sucção (deixe o aspirador pronto e em funcionamento)</li>
                        <li><span className="text-danger-light">T</span>: Teste os equipamentos necessários (de preferência, mais que uma pessoa testando)</li>
                        <li><span className="text-danger-light">O</span>: Oxigênio pronto para a pré-oxigenação</li>
                        <li><span className="text-danger-light">P</span>: Posicione os materiais na mesa para facilitar o procedimento (coloque o tubo orotraqueal levemente curvado em formato anatômico – formato de vírgula)</li>
                        <li><span className="text-danger-light">M</span>: Monitorize o paciente com monitorização multiparamétrica </li>
                        <li><span className="text-danger-light">A</span>: Avalie a via aérea do paciente (veja o mnemônico a seguir)</li>
                        <li><span className="text-danger-light">I</span>: IV – garanta um acesso venoso periférico funcionante </li>
                        <li><span className="text-danger-light">D</span>: Drogas para utilização – escolher e preparar as drogas</li>
                    </ul>
                    <p>
                        A avaliação da via aérea do paciente pode ser feita através da classificação de Mallampati e/ou pela classificação de Cormack-Lehane – CK-L (esta última durante a intubação). Quanto maior o grau da classificação de Mallampati, mais a intubação tende a ser difícil. Se a classificação CK-L é grau I ou II, a chance de sucesso na intubação é alta. No entanto, quando a abertura glótica não pode ser visualizada (CL grau III ou IV), o sucesso da intubação é menos provável, então se antecipe e solicite o kit de via aérea difícil e pense em possíveis complicações. Podemos também lançar mão do mnemônico <span className="text-danger-light">LEMON</span>, no qual devemos avaliar 5 itens:
                        </p>
                    <ul>
                        <li><span className="text-danger-light">L</span>: Look externally (olhe externamente)</li>
                        <li><span className="text-danger-light">E</span>: Evaluate: Avaliação 3-3-2 (3-2 exige que o paciente seja capaz de colocar três de seus próprios dedos entre os incisivos abertos, três dos seus próprios dedos ao longo do assoalho da mandíbula, começando no mento, e dois dedos da proeminência laríngea até a parte inferior do queixo)</li>
                        <li><span className="text-danger-light">M</span>: Mallampati (avalia o acesso oral, sendo considerado um preditor de laringoscopia difícil)</li>
                        <li><span className="text-danger-light">O</span>: Obstruction (ou obesidade)</li>
                        <li><span className="text-danger-light">N</span>: Neck mobility (mobilidade do pescoço – quanto mais difícil mobilizar o pescoço do paciente, mais provável que a intubação seja difícil)</li>

                    </ul>
                </div>
                <div>
                    <strong>2 - Pré-oxigenação</strong>
                    <p>
                        Na pré-oxigenação, deve ser fornecida ao paciente uma fração inspirada de oxigênio <span className="text-danger-light">(FiO2) de 100% durante 3-5 minutos</span>. Com isso, consegue-se uma apneia de 3-5 min antes que a saturação de oxigênio caia para {'<'} 90% em etapas posteriores da SRI. Isso garante uma reserva de oxigênio maior e um prolongamento do tempo de boa saturação de O2, antes que ocorra dessaturação importante.
                        </p>
                </div>
                <div>
                    <strong>3 - Pré-tratamento</strong>
                    <p>
                        Essa etapa é muito importante. É nela em que você irá administrar medicações que irão diminuir resposta simpática do paciente à laringoscopia. As duas principais drogas indicadas são o <span className="text-danger-light">Fentanil</span> e a <span className="text-danger-light">Lidocaína</span>. Elas devem ser administradas pelo menos 3min antes da passagem do tubo, para que seja realmente efetiva e que o paciente não sinta dor (não esqueça! Passar o tubo orotraqueal pela traqueia do paciente é algo extremamente doloroso!).
                        </p>
                </div>
                <div>
                    <strong>4 - Paralisia com indução</strong>
                    <p>
                        Nessa etapa, muitas pessoas se confundem. Apesar do mnemônico 7 P's, a sequência correta é <span className="text-danger-light">primeiro realizar a indução e depois a paralisia muscular</span>. Então, primeiro você irá administrar uma droga hipnótica ou sedativa e, posteriormente, o bloqueador neuromuscular, em bolus. O bloqueador neuromuscular otimiza a sua SRI porque melhora as condições do inserir o tubo orotraqueal e reduzir as chances de aspiração.
                        </p>
                </div>
                <div>
                    <strong>5 - Posicionamento do paciente</strong>
                    <p>
                        Nessa etapa, <span className="text-danger-light">posicione</span> o paciente em decúbito dorsal com 30º de inclinação no dorso, em posição de "sniff" ou "farejador" (coxim na região occipital + hiperextensão do pescoço, se não houver contraindicação para tal). O objetivo é alinhar dos eixos oral, laríngeo e faríngeo do paciente
                        </p>
                </div>
                <div>
                    <strong>6 - Posicionamento do tubo com confirmação</strong>
                    <p>
                        Após executar todas as etapas anteriores, insira o tubo orotraqueal seguindo esse passo-a-passo:
                        </p>
                    <ul>
                        <li>Segure <span className="text-danger-light">sempre</span> o laringoscópio com mão esquerda o tubo com mão direita.</li>
                        <li>Coloque laringoscópio pela rima labial direita e desloque a língua para esquerda</li>
                        <li>Tracione o laringoscópio para cima e para frente (com um ângulo médio de 45° em relação ao decúbito do paciente), posicionando-o na valécula <span className="text-danger-light">(evite fazer o movimento de báscula)</span></li>
                        <li>Visualizou as cordas vocais? Caso não tenha visualizado, a intubação não pode ser realizada às cegas, sendo necessário o auxílio de um fio-guia Bougie ou da manobra <span className="text-danger-light">BURP</span> (acrônimo de <span className="text-danger-light">b</span>ackward, <span className="text-danger-light">u</span>pward, <span className="text-danger-light">r</span>ightward <span className="text-danger-light">p</span>ressure on the thyroid cartilage, ou seja, manipulação da cartilagem tireoide para trás, no caso, para região posterior, para cima, para direita e depois pressione). </li>
                        <li>Introduza o tubo até que a borda proximal do cuff ultrapassar as cordas vocais. Parte distal do tubo deve estar a 5-7cm da carina (normalmente isso é a marca de 22cm em adultos)</li>
                        <li>Retire o fio guia</li>
                        <li>Insufle o cuff com seringa de 6 a 10mL de ar (20mmHg)</li>
                        <li>Acople a bolsa-valva-máscara (Ambu®) e insufle, auscultando epigástrio, pulmão esquerdo e pulmão direito, sequencialmente.</li>
                    </ul>
                </div>
                <div>
                    <strong>6 - Pós-intubação</strong>
                    <p>
                        Nessa última etapa, chamada <span className="text-danger-light">pós-intubação</span>, você já confirmou a intubação e na sequência deve fixar o tubo com acessórios próprios ou mais comumente esparadrapo. Nela, é necessário a realização de uma radiografia de tórax para verificar a posição do tubo ou evidenciar alguma complicação seletividade do mesmo.
                        </p>
                    <p>
                        Após fixação, coloque o paciente acoplado ao ventilador mecânico e o mantenha monitorizado. Após a intubação, o paciente pode cursar com instabilidade hemodinâmica, evoluindo com hipotensão – que é um evento bastante comum, devido aos efeitos das drogas usadas na indução ou após o início da ventilação mecânica, por conta da diminuição do retorno venoso.
                        </p>
                </div>
                <span>Referências:</span>
                <ul>
                    <li>Vissers RJ, Danzl DF. Intubation and mechanical ventilation in Tintinalli Emergency Medicine 2016.</li>
                    <li>American College of Surgeons Committee on Trauma. Suporte avançado de vida no trauma: ATLS: Advanced Trauma Life Support. Chicago: Elsevier; 2018.</li>
                    <li>Brown, C.A. The decision to intubate. Up to date. 2019. Disponível em: https://www.uptodate.com/contents/the-decision-to-intubate</li>
                    <li>Brown CA, Walls RM. Airway in Rosen’s Emergency medicine 2018.</li>
                    <li>Orebaugh, S., Snyder, J.V. Direct laryngoscopy and endotracheal intubation in adults. Uptodate. 2019. Disponível em: https://www.uptodate.com/contents/direct-laryngoscopy-and-endotracheal-intubation-in-adults</li>
                    <li>Brown, Calvin A., John C. Sakles, and Nathan W. Mick, eds. The Walls Manual of emergency airway management. Philadelphia, PA: Wolters Kluwer, 2017.</li>
                </ul>
            </Card.Body>
        </Card>
    )
}

export default memo(OTIGuidance);
