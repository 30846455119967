import React from 'react';
import { useHistory } from "react-router-dom";
import { Row } from 'react-bootstrap';
import PageTitle from '../../shared/components/PageTitle';
import DrugCard from '../../modules/intensiveCare/components/DrugCard';
import { AMPOULE_BLUE_64, AMPOULE_YELLOW_64, AMPOULE_RED_64, AMPOULE_GRAY_64, AMPOULE_ORANGE_64, AMPOULE_VIOLET_64 } from "../../assets/images";

const Drugs = (props: any) => {
    let { path } = props;
    const history = useHistory();

    const back = () => {
        history.goBack();
    }

    const getIconName = (color: string): string => {
        switch (color) {
            case 'blue': return AMPOULE_BLUE_64;
            case 'yellow': return AMPOULE_YELLOW_64;
            case 'red': return AMPOULE_RED_64;
            case 'orange': return AMPOULE_ORANGE_64;
            case 'gray': return AMPOULE_GRAY_64;
            case 'violet': return AMPOULE_VIOLET_64;
            case undefined: 
            default:
                return AMPOULE_GRAY_64;
        }
    }

    return (
        <>
            <Row className="pl-1">
                <PageTitle backEvent={back} title={props.title} />
            </Row>
            <Row>
                {
                    props.drugs.map((drug: any) =>
                        <DrugCard key={drug.key} drug={drug} icon={getIconName(drug.color)} pathTo={path + drug.key} />
                    )
                }
            </Row>
        </>
    )
}

export default Drugs;