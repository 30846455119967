import { useState, useEffect } from "react";
import DrugType from "../models/DrugType";
import { getMaintenanceDrug, getRapidSequenceDrug, getVasoactiveDrug } from "../services/core/DrugSelector";

interface useDrugProps {
    drugType: Number,
    drugId: any
}
const useDrug = (props: useDrugProps) => {
    const [drug, setDrug] = useState<any>();
    const [found, setFound] = useState<boolean | undefined>(undefined);
    useEffect(() => {
        const select = () => {
            let d: any = undefined;
            switch (props.drugType) {
                case DrugType.RAPID_SEQUENCE:
                    d = getRapidSequenceDrug(props.drugId);
                    break;
                case DrugType.MAINTENANCE:
                    d = getMaintenanceDrug(props.drugId);
                    break;
                case DrugType.VASOACTIVE:
                    d =  getVasoactiveDrug(props.drugId);
                    break;
                default:
                    break;
            }
            if(d) {
                setDrug(d);
            } else {
                setFound(false);
            }
        }
        select();
    }, [props.drugType, props.drugId]);
    return { drug, found };
}

export default useDrug;