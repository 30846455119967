export const references = [
    'Martins, H. S.; Brandão Neto, R. A.; Scalabrini Neto, A.; Velasco, I. T., Emergências Clínicas: abordagem prática; 10ª Ed. São Paulo:Manole,2015.',
    'Manual de medicina intensiva : AMIB. -- São Paulo : Editora Atheneu, 2014.',
    'Oliveira AR, et al. Manual da Residência de Medicina Intensiva. 2ª edição. Manole, 2011.',
    'Brasil. Agência Nacional de Vigilância Sanitária – Anvisa. Bulário Eletrônico. Portal Anvisa. Disponível online.',
]

function Vasoactive(key, name, type, presentations, concentrations, concentrationTextTemplate, doseMin, doseMax, doseStep, doseUnit, 
    timeUnit, volumeUnit, characteristics, receivers, effects, usage, contraindications, useWeight=true, color='violet') {
    this.key = key;
    this.name = name;
    this.type = type;
    this.presentations = presentations;
    this.concentrations = concentrations;
    this.concentrationTextTemplate = concentrationTextTemplate;
    this.doseMin = doseMin;
    this.doseMax = doseMax;
    this.doseStep = doseStep;
    this.doseUnit = doseUnit;
    this.timeUnit = timeUnit;
    this.volumeUnit = volumeUnit;
    this.characteristics = characteristics;
    this.receivers = receivers;
    this.effects = effects;
    this.usage = usage;
    this.contraindications = contraindications;
    this.useWeight = useWeight;
    this.color = color;
}

function Concentration(id, ampouleDose, ampouleVolume, solutionVolume, multipliers, label, subtractAmpoule = true, ampouleDosePerMl = 1) {
    this.id = id
    this.ampouleDose = ampouleDose;
    this.ampouleVolume = ampouleVolume;
    this.solutionVolume = solutionVolume;
    this.multipliers = multipliers;
    this.label = label;
    this.subtractAmpoule = subtractAmpoule;
    this.ampouleDosePerMl = ampouleDosePerMl;
}

const vasoactive = [
    new Vasoactive(
        1,
        'Noradrenalina',
        'Vasoativa',
        [
            'Ampola de bitartarato de norepinefrina 4mg(4000µg)/4mL',
            'Ampola de hemitartarato de norepinefrina 8mg(8000µg)/4mL',
        ],
        [
            new Concentration(1, 4000, 4, 250.0, [1, 2, 4, 8], '4mg(4000µg)/4mL', true, 4),
            // new Concentration(2, 8000, 4, 250.0, [1, 2, 4, 8], '8mg(8000µg)/4mL', true, 4),
        ],
        '@ampoules ampola(s) de Noradrenalina (@concentration) diluída em @volumemL de soro glicosado à 5%',
        0.01,
        2.0,
        0.01,
        'µg',
        'min',
        'mL',
        [
            'Precursor natural da Adrenalina',
            'A ampola de hemitartarato de norepinefrina 8mg(8000µg)/4mL equivale a 4mg/4mL de bitartarato norepinefrina',
        ],
        [
            'α1',
            'α2',
            'β1 (fraco agente inotrópico)',
        ],
        [
            'Aumenta da resistência vascular periférica (vasoconstrição)',
            'Aumenta da pressão arterial (sistólica e diastólica)',
        ],
        [
            'Choque insensível à reposição volêmica (séptico, neurogênico, cardiogênico grave e hipovolêmico)',
            'Insuficiência ventricular direita',
            'Embolia pulmonar maciça',
            'Preferir administrar em veia central (pode iniciar em veia periférica se por curto tempo e em baixas doses)',
            'Evitar administrar na mesma linha endovenosa junto com bicarbonato',
        ],
        [
            'Hipersensibilidade aos componentes da fórmula',
            'Hipotensão pro deficit de volume sanguíneo',
            'Trombose vascular periférica',
        ],
    ),
    new Vasoactive(
        2,
        'Adrenalina',
        'Vasoativa',
        [
            'Ampola de 1mg/1mL',
        ],
        [
            new Concentration(1, 1000, 1, 250.0, [16], '1mg/1mL', true, 1),
        ],
        '@ampoules ampola(s) de Adrenalina (@concentration) diluída em @volumemL de soro glicosado à 5%',
        0.1,
        10,
        0.01,
        'µg',
        'min',
        'mL',
        [
            'Catecolamina de ação sobre os receptores adrenérgicos',
            'Seus efeitos são dose-dependente',
        ],
        [
            'α1 e α2 (altas doses: maior ação nos receptores alfa - vasoconstrição)',
            'β1',
            'β2 (Baixas doses: maior ação nos receptores beta - vasodilatação e broncodilatação)',
        ],
        [
            'Potente vasoconstritor',
            'Aumento da pressão arterial e do débito cardíaco',
            'Aumento da contratilidade cardíaca e do consumo miocárdico de O2',
            'Aumento da resistência vascular sistêmica e redução do fluxo sanguíneo esplâncnico',
            'Broncodilatação',
            'Redução de liberação de antígenos e mediadores inflamatórios dos mastócitos',
        ],
        [
            'Parada cardiorrespiratória (administrar 1mg em bolus a cada 3-5 minutos, sem dose máxima)',
            'Choque circulatório refratário à volume',
            'Anafilaxia (com ou sem choque)',
            'Bradicardias sintomáticas sem resposta à Atropina, Dopamina (0,5mg/dose em bolus ou infusão contínua 2-10mcg/min)',
        ],
        undefined
    ),
    new Vasoactive(
        3,
        'Dobutamina',
        'Vasoativa',
        [
            'Ampola de 250mg/20mL',
        ],
        [
            new Concentration(1, 250000, 20, 250.0, [1,2,4], '250mg/20mL', true, 20),
        ],
        '@ampoules ampola(s) de Dobutamina (@concentration) diluída em @volumemL de soro glicosado à 5%',
        2.5,
        20,
        0.1,
        'µg',
        'min',
        'mL',
        [
            'Catecolamina de potente ação β-adrenérgica / ação modesta nos receptores α',
            'Seus efeitos são dose-dependente',
            'Dose máxima recomendada é de 40µg/Kg/min',
            '2.5-10µg/Kg/min (aumenta débito cardíaco)',
            '15-25µg/Kg/min (aumenta débito cardíaco e aumenta frequência cardíaca)',
            '> 30µg/Kg/min (diminui resistência vascular pulmonar e aumenta risco de arritmias)',
        ],
        [
            'β1 (miocárdio)',
            'β2 (brônquios e vasos)',
            'α1 (vasos)',
        ],
        [
            'Forte estímulo inotrópico aos receptores β1 e α1',
            'Fraco estímulo aos receptores β2 (vasos periféricos) - vasodilatação discreta',
        ],
        [
            'Choque cardiogênico',
            'Infarto de ventrículo direito com repercussão hemodinâmica (adjuvante)',
            'Síndromes de baixo débito cardíaco por falência de bomba',
        ],
        [
            'Cuidado! Não prescrever com o objetivo de elevar a pressão arterial em hipotensão severa',
            'Evitar uso em pacientes com taquiarritmias, estenose aórtica grave e cardiomiopatia obstrutiva',
        ]
    ),
    new Vasoactive(
        4,
        'Dopamina',
        'Vasoativa',
        [
            'Ampola de 50mg/10mL',
        ],
        [
            new Concentration(1, 50000, 10, 250.0, [5], '50mg/10mL', true, 10),
        ],
        '@ampoules ampola(s) de Dopamina (@concentration) diluída em @volumemL de soro glicosado à 5% / soro Fisiológico à 0,9%',
        1,
        20,
        0.1,
        'µg',
        'min',
        'mL',
        [
            'Catecolamina precursora da adrenalina e da noradrenalina',
            'Seus efeitos são dose-dependente',
            '1-4µg/Kg/min (ação dopaminérgica → vasodilatação renal)',
            '5-10µg/Kg/min (aumento do débito cardíaco e do retorno venoso)',
            '10-20µg/Kg/min (dose para bradicardia sintomática → aumento da resistência vascular periférica e da pressão arterial. Reduz o fluxo renal e mesentérico)',
        ],
        [
            'α1 e α2 (alfa-adrenérgicos)',
            'β1 e β2 (beta-adrenérgicos)',
            'DA1 e DA2 (dopaminérgicos)',
        ],
        [
            'Aumento da resistência vascular e aumentar do débito cardíaco',
            'Reduz o fluxo sanguíneo renal e mesentérico',
        ],
        [
            'Choque cardiogênico',
            'Hipotensão associada à choque (PAM < 65mmHg) - 2ª linha',
            'Bradicardia sintomática refratária à Atropina',
            'Insuficiência de ventrículo direito com hipotensão ',
        ],
        [
            'Hipersensibilidade ao fármaco',
            'Fibrilação ventricular',
            'Feocromocitoma',
            'Taquiarritmias ',
        ]
    ),
    new Vasoactive(
        5,
        'Vasopressina',
        'Vasoativa',
        [
            'Ampola de 20U/1mL',
        ],
        [
            new Concentration(1, 20, 1, 100, [1], '20U/1mL', false, 1),
        ],
        '@ampoules ampola(s) de Vasopressina (@concentration) diluída em @volumemL de soro Fisiológico à 0,9%',
        0.01,
        0.04,
        0.01,
        'U',
        'min',
        'mL',
        [
            'Análogo sintético do ADH',
            'Ação direta sobre a musculatura lisa arteriolar',
        ],
        [
            'V1 (músculo liso vascular)',
            'V2 (ductos coletores renais)',
            'V3 (adeno-hipófise)',
        ],
        [
            'Vasoconstrição',
            'Redução da diurese',
            'Aumento da pressão arterial',
        ],
        [
            'Choque séptico refratário',
            'Parada cardiorrespiratória (alternativa à adrenalina)',
        ],
        [
            'Hipersensibilidade',
        ],
        false
    ),
    new Vasoactive(
        6,
        'Nitroprussiato de Sódio',
        'Vasoativa',
        [
            'Ampola de 50mg/2mL',
        ],
        [
            new Concentration(1, 50000, 2, 250, [1], '50mg/2mL', true, 2),
        ],
        '@ampoules ampola(s) de Nitroprussiato de Sódio (@concentration) diluída em @volumemL de soro glicosado à 5%',
        0.25,
        10,
        0.01,
        'µg',
        'min',
        'mL',
        [
            'Agente vasodilatador balanceado (mais arterial, menos venoso)',
            'Ação sobre o músculo vascular',
        ],
        [
        ],
        [
            'Vasoconstrição coronariana',
            'Reduz a pressão arterial e o O2 miocárdico',
            'Reduz a pós-carga (aumenta o débito cardíaco) e reduz a pré-carga (reduz a pressão de enchimento ventricular)',
        ],
        [
            'Emergência hipertensiva que é necessária reduzir rapidamente a pré e pós-carga',
            'Aneurisma dissecante de aorta',
            'Edema agudo de pulmão',
            'Insuficiência cardíaca congestiva severa (secundário ou não à causa isquêmica)',
        ],
        [
            'Hipersensibilidade',
            'Evitar em coarctação da aorta, insuficiência cardíaca congestiva de alto débito',
            'Evitar no infarto agudo do miocárdio',
        ],
    ),
    new Vasoactive(
        7,
        'Nitroglicerina',
        'Vasoativa',
        [
            'Ampola de 5mg/mL (ampola de 10mL)',
        ],
        [
            new Concentration(1, 5000, 10, 250, [1], '5mg/mL (10mL)', true, 1),
        ],
        '@ampoules ampola(s) de Nitroglicerina (@concentration) diluída em @volumemL de soro glicosado à 5%/soro fisiológico à 0,9%',
        5,
        200,
        1,
        'µg',
        'min',
        'mL',
        [
            'Agente predominantemente venoso (com pouca ação arterial)',
        ],
        [
        ],
        [
            'Vasodilatação coronariana (especialmente áreas subendocárdicas)',
            'Reduz consumo miocárdico de O2 → aumenta performance cardíaca, volume sistólico e alívio da congestão pulmonar',
            'Reduz pré-carga e congestão pulmonar',
            'Doses elevadas → efeito arteriodilatador → reduz pós-carga',
        ],
        [
            'Insuficiência cardíaca congestiva sem hipotensão',
            'Insuficiência coronariana aguda e crônica aorta',
            'Edema agudo de pulmão',
            'Pós-operatório de revascularização miocárdica',
        ],
        [
            'Hipersensibilidade',
            'Infarto agudo do miocárdio de ventrículo direito',
            'Associado com inibidores da fosfodiesterase ',
            'Trauma craniano ou hemorragia cerebral',
            'Gestação',
        ],
        false
    ),
];

export default vasoactive;