import React, { memo } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";

const HemodynamicGuidance = () => {
    return (
        <Card className="mb-2">
            <Card.Body>
                <p>
                    <FontAwesomeIcon icon="play" size="sm" /> <strong>Introdução</strong>
                </p>
                <p>
                    A monitorização hemodinâmica é essencial para diagnóstico e manejo terapêutico dos pacientes com distúrbios hemodinâmicos. Além disso, conhecer a origem dessa disfunção é fundamental.
                </p>
                <p>
                    Em estados de choque circulatório, devemos sempre buscar entender a fisiopatologia por trás, encaixando o choque em: Hipovolêmico, Cardiogênico, Obstrutivo ou Distributivo (devemos ter em vista que essas condições podem coexistir).
                </p>
                <p>
                    Para monitorização podemos lançar mão de inúmeras variáveis. A mais simples e direta é a Pressão Arterial, que pode ser não invasiva (a partir do esfigmomanômetro) ou invasiva (a partir de instalação de cateter em artéria radial).
                </p>
                <p>
                    Outros parâmetros podem ser utilizados, como a medida da Pressão Venosa Central (PVC); Pressão de oclusão da Artéria Pulmonar (POAP); Débito Cardíaco; Índice cardíaco.
                </p>
                <p>
                    O manejo do choque parte da utilização de ressuscitação/reposição volêmica até o uso de drogas vasoativas.
                </p>
                <p>
                    <FontAwesomeIcon icon="play" size="sm" /> <strong>Algumas variáveis da monitorização hemodinâmica</strong>
                </p>
                <p>
                    <strong>Pressão Arterial Média (PAM):</strong> Parâmetro mais direto para avaliar instabilidade hemodinâmica. Objetivaremos uma PAM sempre acima de 65mmHg. A pressão arterial corresponde ao produto do débito cardíaco e da resistência vascular periférica, dependendo diretamente dessas duas variáveis.<br />
                        A monitorização da PA pode ser feita de forma não invasiva em pacientes estáveis, através do esfigmomanômetro e de forma invasiva em pacientes instáveis através de um cateter arterial.<br />
                        Pacientes que apresentam PAM {'<'} 65mmHg devem ser manejados com uso de volume ou drogas vasoativas para promover a estabilidade hemodinâmica.
                </p>
                <p>
                    <strong>Pressão Venosa Central (PVC):</strong> Consegue se a partir de um cateter venoso central. Corresponde à força exercida pelo sangue na veia cava superior, refletindo a pressão no átrio direito, portanto seu valor depende do Débito Cardíaco e do retorno venoso (em geral, da volemia).<br />
                    <strong>PVC baixa com queda da pré-carga: Hipovolemia</strong><br />
                    <strong>PVC alta com aumento das pressões de enchimento: Disfunção cardíaca</strong>
                </p>
                <p>
                    <strong>Pressão de oclusão da Artéria Pulmonar (POAP):</strong> Reflete a pressão do átrio esquerdo, pressão diastólica final do ventrículo esquerdo e volume diastólico final, correspondendo a função ventricular sistólica e diastólica, além de complacência e volemia. Essa variável é obtida através do cateter de artéria pulmonar (Swan Ganz).<br />
                    <strong>PAOP reduzida: Hipovolemia</strong><br />
                    <strong>PAOP aumentada: Disfunção ventricular esquerda ou Hipervolemia</strong>
                </p>
                <p>
                    <strong>Débito Cardíaco:</strong> É o reflexo da força de contração do ventrículo esquerdo e do volume diastólico final e é determinado pelo produto da frequência cardíaca e do volume sistólico. Pode ser obtida através do ECO, ou da análise do contorno da onda de pulso (por cateter arterial). <br />
                    <strong>DC reduzido: pode representar hipovolemia ou disfunção sistólica</strong><br />
                    <strong>DC aumentado: aumento da demanda tecidual</strong><br />
                        Todos esses parâmetros vão indicar a necessidade de reposição volêmica e/ou uso de drogas vasoativas e inotrópicas. Contudo não conseguem determinar se o paciente é responsivo a volume, ou seja, se após a administração de volume, ocorre aumento do débito cardíaco e da PA.

                    </p>
                <p>
                    <FontAwesomeIcon icon="play" size="sm" /> <strong>Quais serão os pacientes responsivos a volume?</strong>
                </p>
                <p>
                    São aqueles que se beneficiam do aumento do retorno venoso, determinando maior interação entre as fibras cardíacas e, portanto, maior força de contração (pacientes na porção ascendente da curva de Frank-Starling).
                    </p>
                <p>
                    <FontAwesomeIcon icon="play" size="sm" /> <strong>Como saber se o paciente é ou não responsivo a volume?</strong>
                </p>
                <b>Delta de Pressão de Pulso (Delta PP):</b> Para se obter essa variável é necessário o paciente estar em ventilação mecânica (VM), em modo controlado, e com PA invasiva.<br />
                <p>
                    Durante a VM ocorre aumento da pressão na caixa torácica, isso gera um aumento na pós carga e redução da pré carga do VD, mas aumenta a pré carga do VE, levando a um aumento do volume sistólico do VE, portanto, leva a uma <span className="text-danger-light">pressão de pulso máxima no final da inspiração</span>. Por outro lado, reduz o volume sistólico do VD e alguns batimentos depois, gera uma redução na pré carga do VE e consequentemente redução do volume sistólico do VE, isso já no final da expiração, gerando uma <span className="text-danger-light">pressão de pulso mínima na expiração</span>.<br />
                </p>
                <p>
                    Delta PP = 100 x (PPmáx – PPmin na inspiração)/( PPmáx + PPmin na expiração) ÷ 2
                        </p>
                <p>
                    {'Paciente será fluido responsivo se o Delta PP (na VM em modo controlado com volume corrente >= a 7ml/kg) for > 13% ou se na VM com volume corrente < 7ml/Kg for > 8%.'}
                </p>
                <p>
                    <strong>Elevação passiva das pernas:</strong> É um teste para avaliar responsividade a volume. Realiza-se a elevação das pernas do paciente, durante esse processo ocorrerá o aumento do retorno venoso ao coração, aumentando a pré-carga. Nos pacientes respondedores a volume ocorre um aumento de 10% no débito cardíaco (que pode ser avaliado pelo ECO ou pela análise da onda de pulso). Caso não haja aumento do débito cardíaco, o paciente não se beneficia com volume.
                    </p>
                <p>
                    <strong>Prova de volume:</strong> Em casos onde não se pode fazer a análise da curva de onda de pulso ou não há disponibilidade do ECO, pode-se fazer a prova de volume. Ao administrar um volume de cristaloide em curto período de tempo, em paciente respondedor a volume, espera-se elevações dos parâmetros hemodinâmicos como PA e diurese. Nesses pacientes poderemos realizar novas alíquotas de volume, sempre realizando avaliação para evitar sobrecargas de volume e consequentemente congestão.
                    </p>
                <p>
                    <FontAwesomeIcon icon="play" size="sm" /> <strong>Quando utilizar os inotrópicos?</strong>
                </p>
                <p>
                    Os inotrópicos são drogas que vão auxiliar na força de contração do miocárdio. Eles serão utilizados principalmente nos casos de falha da bomba cardíaca, sobretudo choque cardiogênico.
                    </p>
                <p>
                    <FontAwesomeIcon icon="play" size="sm" /> <strong>Quando utilizar as drogas vasoativas?</strong>
                </p>
                <p>
                    As drogas vasoativas (adrenalina, noradrenalina e vasopressina) geralmente serão utilizadas nos quadros de choque, em condições que precisaremos resolver uma hipotensão grave do paciente. Essas drogas, de modo geral, dentre outros efeitos, vão promover uma vasoconstricção, aumentando a resistência vascular periférica, elevando por consequência a pressão arterial.
                    </p>
                <p>
                    Geralmente iniciamos uma expansão volêmica (nos pacientes que podem se beneficiar de fluido: choque séptico e hipovolêmico), caso o paciente não atingir uma PAM {'>'} 65mmHg em até 30min, devemos iniciar a droga vasoativa.
                    </p>
                <p>
                    Pacientes que já se encontram com doses altas de Noradrenalina {'(> 0,5 mcg/kg/min)'}, será interessante iniciar Vasopressina concomitante.
                    </p>
                <p>
                    <span className="text-danger-light">Lembrando!</span> A via preferencial para uso dessas drogas é através de acesso venoso central. Contudo, podemos iniciá-las em acesso venoso periférico até a obtenção do acesso central.
                    </p>
                <p>
                    <FontAwesomeIcon icon="play" size="sm" /><strong> Quais parâmetros para avaliação da perfusão tecidual?</strong>
                </p>
                <p>
                    <strong>Pressão Arterial Média (PAM):</strong> A estabilização da pressão é um bom parâmetro para avaliar perfusão tecidual. Objetivaremos uma PAM {'>'} 65mmHg.
                    </p>
                <p>
                    <strong>Tempo de Enchimento Capilar:</strong> Faz parte do exame físico. Pressiona-se o leito ungueal do paciente por cerca de 15 segundos e após soltar, o tempo de enchimento capilar adequado deve ser menor que 4,5 segundos. Tempos maiores, indicam uma má perfusão tecidual.
                    </p>
                <p>
                    <strong>Débito Urinário:</strong> A diurese reflete a perfusão dos rins e é um marcador precoce e tem valor prognóstico em pacientes em choque. O débito urinário adequado é {'>'} 5ml/Kg/hora.
                    </p>
                <p>
                    <strong>Lactato:</strong> É um metabólico orgânico, produto do metabolismo anaeróbico. Se encontra aumentado em situações de falta de oxigênio adequado ou quando ocorre redução da sua depuração. É um ótimo marcador de perfusão tecidual, quanto maior seu valor, pior é o estado de perfusão tecidual.
                    </p>
                <p>
                    <strong>Nível de Consciência:</strong> Reflete a perfusão cerebral. Não é um marcador mensurável de perfusão tecidual, porém faz parte da avaliação. É um importante componente, uma vez que em boa parte das vezes é o primeiro a se alterar, evoluindo de um estado de agitação (em condições mais leves), passando pela sonolência e até o coma (em situações mais críticas).
                    </p>
                <span>Referências:</span>
                <ul>
                    <li>
                        Manual de Medicina Intensiva da Associação de Medicina Intensiva Brasileira (AMIB). Atheneu, 2014
                        </li>
                    <li>
                        Apostila de Monitorização Hemodinâmica e Choque da Associação de Medicina Intensiva Brasileira (AMIB)
                        </li>
                    <li>
                        Medicina Intensiva, abordagem prática. HCFMUSP. 2ª ed, Manole, 2015
                        </li>
                </ul>
            </Card.Body>
        </Card>
    )
}

export default memo(HemodynamicGuidance);