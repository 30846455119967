import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Row } from 'react-bootstrap';
import useDrug from '../../modules/intensiveCare/hooks/useDrug';
import PageTitle from "../../shared/components/PageTitle";
import RapidSequence from "../../modules/intensiveCare/components/RapidSequenceCalc";
import Skeleton from 'react-loading-skeleton';
import DrugType from '../../modules/intensiveCare/models/DrugType';
import Vasoactive from '../../modules/intensiveCare/components/VasoactiveCalc';

interface DrugPageProps {
    drugId: Number,
    drugType: Number,
}

const Drug = (props: DrugPageProps) => {
    const { drug, found } = useDrug({ drugType: props.drugType, drugId: props.drugId });
    let history = useHistory();

    const onClickBack = () => {
        history.goBack();
    }

    useEffect(() => {
        if(found === false) {
            history.push('/')
        }
    }, [found, history]);

    const getDrugComponent = (drug: any) => {
        if(props.drugType === DrugType.RAPID_SEQUENCE) {
            return <RapidSequence drug={drug} />
        } else {
            return <Vasoactive drug={drug} />
        }
    }

    return (
        <>
            <Row className="pl-1">
                <PageTitle backEvent={drug ? onClickBack : null} title={drug?.name} />
            </Row>
            {
                drug ?
                getDrugComponent(drug)
                :
                <Skeleton height={300} />
            }
        </>
    )
}

export default Drug;