function Maintenance(key, name, shortName, type, presentations, concentrations, concentrationTextTemplate, doseMin, doseMax, doseStep, doseUnit, 
    timeUnit, volumeUnit, characteristics, receivers, effects, usage, indications, contraindications, useWeight=true, color = 'gray') {
    this.key = key;
    this.name = name;
    this.shortName = shortName;
    this.type = type;
    this.presentations = presentations;
    this.concentrations = concentrations;
    this.concentrationTextTemplate = concentrationTextTemplate;
    this.doseMin = doseMin;
    this.doseMax = doseMax;
    this.doseStep = doseStep;
    this.doseUnit = doseUnit;
    this.timeUnit = timeUnit;
    this.volumeUnit = volumeUnit;
    this.characteristics = characteristics;
    this.receivers = receivers;
    this.effects = effects;
    this.usage = usage;
    this.indications = indications;
    this.contraindications = contraindications;
    this.useWeight = useWeight
    this.color = color;
}

function Concentration(id, ampouleDose, ampouleVolume, solutionVolume, multipliers, label, subtractAmpoule = true, ampouleDosePerMl = 1) {
    this.id = id;
    this.ampouleDose = ampouleDose;
    this.ampouleVolume = ampouleVolume;
    this.solutionVolume = solutionVolume;
    this.multipliers = multipliers;
    this.label = label;
    this.subtractAmpoule = subtractAmpoule;
    this.ampouleDosePerMl = ampouleDosePerMl;
}

const maintenance = [
    new Maintenance(
        1,
        'Fentanil',
        'Fentanil',
        'Manutenção',
        [
            'Ampola de citrato de fentanila 50µg/mL (2mL)',
            'Ampola de citrato de fentanila 50µg/mL (5mL)',
            'Ampola de citrato de fentanila 50µg/mL (10mL)',
        ],
        [
            new Concentration(3, 50, 10, 250.0, [4], '50µg/mL (10mL)'),
            new Concentration(1, 50, 2, 250.0, [20], '50µg/mL (2mL)'),
            new Concentration(2, 50, 5, 250.0, [8], '50µg/mL (5mL)'),
        ],
        '@ampoules ampola(s) de Fentanil (@concentration) diluída em @volumemL de soro glicosado à 5%',
        0.7,
        10,
        0.1,
        'µg',
        'h',
        'mL',
        [
            'Analgésico opióide potente (100x maior que a morfina)',
            'Reduzir vazão a cada hora até menor dose necessária',
            'Cuidado com pacientes hemodinamicamente instáveis',
            'Doses muito altas podem causar rigidez torácica',
        ],
        undefined,
        undefined,
        undefined,
        undefined,
        [
            'Hipersensibilidade aos componentes da fórmula',
            'Depressão respiratória significativa',
            'Obstrução intestinal conhecida ou suspeita',
        ],
        true,
        'blue'
    ),
    new Maintenance(
        2,
        'Midazolam',
        'Midazolam',
        'Manutenção',
        [
            'Ampola de 5mg/ml (10ml)',
            'Ampola de 5mg/ml (3ml)',
            'Ampola de 1mg/ml (5ml)',
        ],
        [
            new Concentration(1, 5, 10, 250.0, [5], '5mg/ml (10ml)'),
            new Concentration(2, 5, 3, 250.0, [34], '5mg/ml (3ml)'),
            new Concentration(3, 1, 5, 250.0, [10], '1mg/ml (5ml)'),
        ],
        '@ampoules ampola(s) de Midazolam (@concentration) diluída em @volumemL de soro glicosado à 5%',
        0.02,
        0.6,
        0.01,
        'mg',
        'h',
        'mL',
        [
            'Benzodiazepínico usado como sedativo e hipnótico',
            'Causa instabilidade hemodinâmica',
            'Evitar em pacientes idosos',
        ],
        undefined,
        undefined,
        undefined,
        [
            'Estados de mal epiléptico',
            'Sedação contínua em UTI'
        ],
        [
            'Hipersensibilidade a qualquer componente da fórmula',
            'Intoxicação alcoólica aguda',
            'Hipotensão',
        ],
        true,
        'orange'
    ),
    new Maintenance(
        3,
        'Propofol 1%',
        'Propofol 1%',
        'Manutenção',
        [
            'Frasco de 10mg/mL (50ml)',
        ],
        [
            new Concentration(1, 10, 50, undefined, [1], '10mg/mL (50ml)'),
        ],
        '@ampoules frasco de Propofol 1% (@concentration) puro, em bomba de infusão contínua',
        0.5,
        5,
        0.1,
        'mg',
        'h',
        'mL',
        [
            'Hipnótico barbitúrico que causa instabilidade hemodinâmica',
            'Não ultrapassar 48-72h de infusão contínua (sedoanalgesia contínua) - risco de síndrome da infusão do Propofol',
        ],
        undefined,
        undefined,
        undefined,
        undefined,
        [
            'Hipersensibilidade a qualquer componente da fórmula',
            'Gestantes',
            'Hipotensão',
            'Alergia a ovo e soja',
        ],
        true,
        'yellow'
    ),
    new Maintenance(
        4,
        'Quetamina',
        'Quetamina',
        'Manutenção',
        [
            'Ampola de 50mg/mL (10mL)',
        ],
        [
            new Concentration(1, 50, 10, 500.0, [1], '50mg/ml (10ml)'),
        ],
        '@ampoules ampola(s) de Quetamina (@concentration) diluída em @volumemL de soro glicosado à 5%',
        0.1,
        0.5,
        0.01,
        'mg',
        'min',
        'mL',
        [
            'Sedativo com ação analgésica e amnéstica',
            'Estimula a liberação de catecolaminas (promove aumento da: pressão arterial, frequência cardíaca, fluxo sanguíneo sistêmico e cerebral)',
            'Não reduz o drive respiratório',
            'Tem efeito broncodilatador',
        ],
        undefined,
        undefined,
        [
            'A cada aumento de 6ml/h na infusão, aumenta-se 0.1mg/min na dose total',
        ],
        [
            'Uso recomendado em pacientes instáveis, com asma grave e em pacientes  pediátricos',
        ],
        [
            'Hipersensibilidade a qualquer componente da fórmula',
            'Evitar uso nas emergências hipertensivas e na síndrome coronariana aguda',
        ],
        false,
        'yellow'
    ),
    new Maintenance(
        5,
        'Dexmedetomidina (Precedex)',
        'Dexmedetomidina',
        'Manutenção',
        [
            'Ampola de dexmedetomidina 100µg/mL (2mL)',
        ],
        [
            new Concentration(1, 100, 2, 100.0, [2], '100µg/mL (2mL)'),

        ],
        '@ampoules ampola(s) de Dexmedetomidina (@concentration) diluída em @volumemL de soro fisiológico à 0,9%',
        0.2,
        0.7,
        0.01,
        'µg',
        'h',
        'mL',
        [
            'É um alfa 2 agonista seletivo',
            'Tem efeito sedativo, ansiolítico, hipnótico, analgésico e simpatolítico',
            'Promove hipotensão (algumas vezes hipertensão) e bradicardia',
        ],
        undefined,
        undefined,
        undefined,
        [
            'Sedação em UTI, sala de cirurgia ou para procedimentos diagnósticos',
            'Usada para desmame de ventilação mecânica em UTI',
        ],
        [
            'Hipersensibilidade a qualquer componente da fórmula',
            'Evitar uso em pacientes hipotensos e bradicardicos',
        ],
        true,
        'yellow'
    ),
    new Maintenance(
        6,
        'Cisatracúrio',
        'Cisatracúrio',
        'Manutenção',
        [
            'Ampola de cisatracúrio 2000µg/mL (5mL)',
            'Ampola de cisatracúrio 2000µg/mL (10mL)',
        ],
        [
            new Concentration(1, 2000, 5, undefined, [10], '2000µg/mL (5mL)'),
            new Concentration(2, 2000, 10, undefined, [5], '2000µg/mL (10mL)'),
        ],
        '@ampoules ampola(s) de Cisatracúrio (@concentration) sem diluir',
        1,
        4,
        0.1,
        'µg',
        'min',
        'mL',
        [
            'Bloqueador neuromuscular não despolarizante de duração intermediária',
            'Atua em receptores colinérgicos da placa motora terminal (antagonizando a ação da acetilcolina)',
        ],
        undefined,
        undefined,
        undefined,
        [
            'Utilizado em associação à sedoanalgesia ou anestesia para relaxamento da musculatura esquelética',
            'Facilita a intubação orotraqueal e a adaptação à ventilação mecânica',
        ],
        [
            'Hipersensibilidade a qualquer componente da fórmula',
        ],
        true,
        'red'
    ),
    new Maintenance(
        7,
        'Pancurônio',
        'Pancurônio',
        'Manutenção',
        [
            'Ampola de Pancurônio de 2mg/mL (2mL)',
        ],
        [
            new Concentration(1, 2, 2, 100, [10], '2mg/mL (2mL)'),
        ],
        '@ampoules ampola(s) de Pancurônio (@concentration) diluída em @volumemL de soro fisiológico à 9%',
        0.02,
        0.1,
        0.01,
        'mg',
        'h',
        'mL',
        [
            'Bloqueador neuromuscular não despolarizante de longa duração',
            'Atua em receptores colinérgicos da placa motora terminal (antagonizando a ação da acetilcolina)',
        ],
        undefined,
        undefined,
        undefined,
        [
            'Utilizado em associação à sedoanalgesia ou anestesia para relaxamento da musculatura esquelética',
            'Facilita a intubação orotraqueal e a adaptação à ventilação mecânica',
            'Pacientes com hipoxemia que resistem à ventilação mecânica e instáveis hemodinamicamente',
        ],
        [
            'Hipersensibilidade a qualquer componente da fórmula',
            'Reações alérgicas ao íon brometo ou ao pancurônio',
            'Miastenia gravis',
        ],
        true,
        'red'
    ),
    new Maintenance(
        8,
        'Rocurônio',
        'Rocurônio',
        'Manutenção',
        [
            'Ampola de Rocurônio de 10mg/mL (5mL)',
        ],
        [
            new Concentration(1, 10, 5, 250, [5], '10mg/mL (5mL)'),
        ],
        '@ampoules ampola(s) de Rocurônio (@concentration) diluída em @volumemL de soro glicosado à 5%',
        0.3,
        0.6,
        0.01,
        'mg',
        'h',
        'mL',
        [
            'Bloqueador neuromuscular não despolarizante de longa duração',
            'Atua em receptores colinérgicos da placa motora terminal (antagonizando a ação da acetilcolina)',
        ],
        undefined,
        undefined,
        undefined,
        [
            'Utilizado em associação à sedoanalgesia ou anestesia para relaxamento da musculatura esquelética',
            'Facilita a intubação orotraqueal e a adaptação à ventilação mecânica',
        ],
        [
            'Hipersensibilidade a qualquer componente da fórmula',
            'Reações alérgicas ao íon brometo ou ao rocurônio',
        ],
        true,
        'red'
    ),
];

export default maintenance;