import React, { memo } from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { Row, Col, Card } from 'react-bootstrap';
import { SHARE_SQUARE, GOOGLE_PLAY_BADGE } from '../../assets/images';
import '../../config/styles/common.scss';
import SectionTitle from '../../shared/components/SectionTitle';

const App = () => {
  return (
    <Row className="mb-2">
      <Col xs="12" lg="6" className="d-flex flex-column">
        <SectionTitle title="Android" />
        <Card className="h-100">
          <Card.Body className="text-center">
            <div className="">
              <a 
                href='https://play.google.com/store/apps/details?id=app.mrcritic&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt='Disponível no Google Play' src={GOOGLE_PLAY_BADGE} className="app-store__img-android" />
              </a>              
            </div>
            O MR Critic também está disponível para Android no Google Play!
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12" lg="6" className="d-flex flex-column">
        <SectionTitle title="iOS" />
        <Card className="h-100">
          <Card.Body className="text-center">
            <h5>
            <FontAwesomeIcon icon={faApple} /> Utiliza iPhone ou iPad?
            </h5>
            <div className="ios-warning-mr">
              Não se preocupe! Enquanto não temos um app para iOS, você pode adicionar um atalho para o site à sua tela inicial. Veja como:
            </div>
            <div>
              <FontAwesomeIcon icon="play" size="sm" /> Acessando a partir do Safari, clique no ícon <img alt="" src={SHARE_SQUARE}></img>  e em "Adicionar à Tela de Início"
            </div>
          </Card.Body>
        </Card>

      </Col>
    </Row>
  );
};

export default memo(App);
