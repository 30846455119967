import axios from "axios";
import config from '../../config/env';

export abstract class Api {
    protected baseUrl: string = config.baseUrl;

    protected async get(route: string, params?: any, headers?: any): Promise<any> {
        return await axios.get(`${this.baseUrl}${route}`, {
            params: params ? params : null,
            headers: headers ? headers : null,
        });
    }

    protected async post(route: string, data: any, params?: any, headers?: any): Promise<any> {
        return await axios.post(`${this.baseUrl}${route}`, data, {
            params: params ? params : null,
            headers: headers ? headers : null,
        });
    }
}