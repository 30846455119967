import React, { memo, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../shared/components/PageTitle';

interface IGuidanceProps {
  title: string,
  children: React.ReactNode
}

const Guidance: FunctionComponent<IGuidanceProps> = props => {
  let history = useHistory();
  const back = () => {
    history.goBack();
  }

  return (
    <>
      <PageTitle backEvent={back} title={props.title} />
      {
        props.children
      }
    </>
  )
}

export default memo(Guidance);