import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducer";

interface ISectionTitleProps {
    title: string,
}

const SectionTitle = (props: ISectionTitleProps) => {
    const userPrefs = useSelector((state: RootState) => state.user.preferences);
    return (
        <div className="section-header">
            <span className={`section-header-text--${userPrefs.theme}`}>{props.title}</span>
            <hr className="section-header-rule" />
        </div>
    );
}

export default SectionTitle;