import React, { memo } from 'react';

import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Drug from '../../models/Drug';
import './index.scss'

interface IDrugCardProps {
    drug: Drug,
    pathTo: string,
    icon: string,
}

const DrugCard = (props: IDrugCardProps) => {
    const { drug, pathTo, icon } = props;
    return (
        <Col key={drug?.name + drug?.key} lg={2} md={3} sm={6} xs={6} className="pl-1 pr-1 pb-2" >
            <Link to={pathTo} className="card-drug-link card-drug-link--light" >
                <div className="card-drug">
                    <div className="card-drug-header">
                        <h5>
                            {drug?.shortName ?? drug?.name}
                        </h5>
                    </div>
                    <div className="card-drug-body">
                        <div className="text-center card-text-mr">
                            <img alt="" src={icon} height="40" />
                        </div>
                    </div>
                    <div className="card-drug-footer">
                        {drug?.type}
                    </div>
                </div>
            </Link>
        </Col>
    )
}

export default memo(DrugCard);
