import React, { memo } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducer";

interface IPageTitleProps {
    title: String,
    backEvent: any,
}

const PageTitle = (props: IPageTitleProps) => {
    const userPrefs = useSelector((state: RootState) => state.user.preferences);
    return (
        <div className="title-container-mr">
            <div>
                {
                    props.backEvent ?
                        <FontAwesomeIcon onClick={props.backEvent} className="button-back-mr" icon={faArrowAltCircleLeft} />
                        :
                        <Skeleton circle={true} height={32} width={32} />
                }

            </div>
            <div className="center-mr">
                {
                    props.title ?
                        <div className="section-header">
                            <span className={`section-header-text--${userPrefs.theme}`}>
                                <span id="page-title-title">
                                    {props.title}
                                </span>
                            </span>
                            <hr className="section-header-rule" />
                        </div>
                        :
                        <Skeleton height={45} />
                }
            </div>
            <div>

            </div>
        </div>
    )
}

export default memo(PageTitle);
