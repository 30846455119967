export default interface Contact {
    name: string,
    email: string,
    subject: SubjectType,
    message: string,
}

export enum SubjectType {
    PROBLEM = "Problema",
    SUGGESTION = "Sugestão",
    COMPLIMENT = "Elogio",
    COMPLAINT = "Crítica",
}