export const references = [
    'Martins, H. S.; Brandão Neto, R. A.; Scalabrini Neto, A.; Velasco, I. T., Emergências Clínicas: abordagem prática; 10ª Ed. São Paulo:Manole,2015.',
    'Manual de medicina intensiva : AMIB. -- São Paulo : Editora Atheneu, 2014.',
    'Oliveira AR, et al. Manual da Residência de Medicina Intensiva. 2ª edição. Manole, 2011.',
    'Brasil. Agência Nacional de Vigilância Sanitária – Anvisa. Bulário Eletrônico. Portal Anvisa. Disponível online.',
]

// Drug class Propotype
function Drug(key, name, type, presentations, concentrations, doseMin, doseMax, doseStep, doseUnit, reverseDoseUnit, characteristics, indications, contraindications, usage, color) {
    this.key = key;
    this.name = name;
    this.type = type;
    this.presentations = presentations;
    this.concentrations = concentrations;
    this.doseMin =doseMin;
    this.doseMax = doseMax;
    this.doseStep = doseStep;
    this.doseUnit = doseUnit;
    this.reverseDoseUnit = reverseDoseUnit;
    this.characteristics = characteristics;
    this.indications = indications;
    this.contraindications = contraindications;
    this.usage = usage;
    this.color = color;
}
// Prototype functions
Drug.prototype.reverseDose = function(currentDose, concentration) { 
    return currentDose/concentration;
}

// Drugs array
const drugs = [
    new Drug(
        1,
        'Fentanil',
        'Analgesia',
        [
            'Ampola de 50µg/mL (10mL)',
        ],
        [
            {value: 50, label: '50µg/mL'},
        ],
        2.0,
        3.0,
        0.01,
        'µg',
        'mL',        
        [
            'Analgésico potente (opióide)',
            'Pode causar instabilidade hemodinâmica quando usados com benzodiazepínicos',
        ],
        [
            'Boa droga para paciente neurocrítico ou com infarto agudo do miocárdio',
        ],
        [
            'Evitar usar em pacientes instáveis hemodinamicamente',
            'Hipersensibilidade a qualquer componente da fórmula',
        ],
        [
            'Administrar de forma lenta, pelo risco de rigidez torácica e risco de depressão respiratória',
            'Usar sem diluição',
        ],
        'blue'
    ),
    new Drug(
        2,
        'Lidocaína 2%',
        'Analgesia',
        [
            'Ampola de 20mg/mL 2% (5mL) - sem vasoconstritor',
        ],
        [
            {value: 20, label: '20mg/mL'},
        ],
        1,
        1.5,
        0.01,
        'mg',
        'mL',
        [
            'Anestésico local e antiarritmico ',
            'Reduz reatividade de vias aéreas',
        ], 
        [
            'Usada como pré droga em paciente com COVID-19'
        ],
        [
            'Hipersensibilidade a qualquer componente da fórmula',
            'Síndrome de Wolf-Parkinson-White',
            'Alergia a milho',
        ],
        [
            'Usar sem diluição'
        ],
        'gray'
    ),
    new Drug(
        3,
        'Etomidato',
        'Sedação',
        [
            'Ampola de 2mg/mL (10mL)',
        ],
        [
            {value: 2, label: '2mg/mL'},
        ],
        0.1,
        0.3,
        0.01,
        'mg',
        'mL',
        [
            'Droga cardioestável e com baixa repercussão hemodinâmica',
            'Reduz a pressão intracraniana (PIC)',
        ],
        [
            'Pacientes com doença  cardiovascular, doença reativa das vias aéreas e hipertensão intracraniana',
        ],
        [
            'Contraindicada na sedação contínua por risco de insuficiência adrenal',
            'Hipersensibilidade a qualquer componente da fórmula',
        ],
        [
            'Usar sem diluição',
            'Infundir em bolus durante 1 minuto',
        ],
        'yellow'
    ),
    new Drug(
        4,
        'Quetamina',
        'Sedação',
        [
            'Ampola de 50mg/mL (10mL)'
        ],
        [
            {value: 50, label: '50mg/mL'},
        ],
        1,
        2,
        0.01,
        'mg',
        'mL',
        [
            'Sedativo com ação analgésica e amnéstica',
            'Estimula a liberação de catecolaminas (promove aumento da: pressão arterial, frequência cardíaca, fluxo sanguíneo sistêmico e cerebral)',
            'Evitar uso nas emergências hipertensivas e na síndrome coronariana aguda',
            'Não reduz o drive respiratório',
            'Tem efeito broncodilatador',            
        ],
        [   
            'Uso recomendado em pacientes instáveis, com asma grave e em pacientes pediátricos',
        ],
        [
            'Evitar uso nas emergências hipertensivas e na síndrome coronariana aguda',
            'Hipersensibilidade a qualquer componente da fórmula'
        ],
        [
            'Usar sem diluição'
        ],
        'yellow'
    ),
    new Drug(
        5,
        'Propofol 1%',
        'Sedação',
        [
            'Ampola de 10mg/mL (20mL)',
        ],
        [
            {value: 10, label: '10mg/mL'},
        ],
        0.5,
        2,
        0.01,
        'mg',        
        'mL',
        [
            'Hipnótico barbitúrico que causa instabilidade hemodinâmica',
            'Possui ação anticonvulsivante (neuroproteção), sendo boa escolha para pacientes com estado de mal epiléptico e aumento permissivo da pressão arterial',
            'Não ultrapassar 48-72h de infusão contínua (sedoanalgesia contínua) - risco de síndrome da infusão do Propofol',
        ], 
        [
            'Paciente neurocrítico e epileptico'
        ], 
        [
            'Hipotensão',
            'Gestantes',
            'Hipersensibilidade a qualquer componente da fórmula'
        ], 
        [
            'Usar sem diluição em bolus'
        ],
        'yellow'
    ),
    new Drug(
        6,
        'Midazolam',
        'Sedação',
        [
            'Ampola de 5mg/mL (3mL ou 10mL)',
            'Ampola de 1mg/mL (5mL)',
        ],
        [
            {value: 1, label: '1mg/mL'}, 
            {value: 5, label: '5mg/mL'}
        ], //concentrationss
        0.1, //doseMin
        0.3, //doseMax
        0.01, //doseStep
        'mg', //doseUnit
        'mL', //reverseDoseUnit
        [
            'Benzodiazepínico usado como sedativo e hipinótico',
            'Causa instabilidade hemodinâmica',
            'Tempo de latência prolongado (pouco útil na sequência rápida)',
            'Melhor indicado para intubação em casos de estado de mal epiléptico',
            'Evitar em pacientes idosos',
        ], 
        [
            'Estados de mal epiléptico'
        ],
        [
            'Hipotensão',
            'Intoxicação alcoólica aguda',
            'Hipersensibilidade a qualquer componente da fórmula',
        ],
        [
            'Objetivo: Formar uma solução de 1mg/ml',
            'Diluir 1 ampola de 3ml (5mg/ml) + 12ml de soro fisiológico 0,9% = 1mg/mL',
            'Diluir 1 ampola de 10ml (5mg/ml) + 40ml de soro fisiológico 0,9% = 1mg/ml',
            'Ampola de 5ml (1mg/ml): não diluir'
        ],
        'orange'
    ),
    new Drug(
        7,
        'Succinilcolina',
        'Bloqueador Neuromuscular',
        [
            'Ampola com pó liofilizado 100mg',
        ],
        [
            {value: 10, label: '10mg/mL'},
        ],
        0.3,
        1.5,
        0.01,
        'mg',
        'mL',
        [
            'Bloqueador neuromuscular despolarizante (análogo da acetilcolina)',
            'Causa fasciculação (indicador da paralisia muscular)',
        ], 
        [
            'Sequência rápida ou atrasada de intubação'
        ],
        [
            'Rabdomiólise',
            'Hipercalemia com alteração do ECG',
            'Grande queimado e/ou AVC por mais de 72h',
            'Intoxicação por carbamato',
            'Hipersensibilidade a qualquer componente da fórmula'
        ],
        [
            'Diluir em 10mL de água destilada (10mg/ml)',
            'Usar apenas uma vez. Não repetir (risco de hipertermia maligna)'
        ],
        'red'
    ),
    new Drug(
        8,
        'Rocurônio',
        'Bloqueador Neuromuscular',
        [
            'Ampola de 10mg/mL (5mL)',
        ],
        [
            {value: 10, label: '10mg/mL'},
        ],
        0.6,
        1.2,
        0.01,
        'mg',
        'mL',
        [
            'Bloqueador neuromuscular não despolarizante',
            'Fármaco que compete pelos receptores nicotínicos da placa motora',
            'Efeito revertido com o uso do Sugammadex',
        ], 
        [
            'Sequência rápida ou atrasada de intubação'
        ],
        [
            'Hipersensibilidade a qualquer componente da fórmula',
            'Não há maiores contraindicações'
        ],
        [
            'Usar em bolus, sem diluição'
        ],
        'red'
    ),    
]

export default drugs;