import React, { useState, useCallback, useMemo } from 'react'

import PageTitle from "../../../shared/components/PageTitle";
import theme from "../../../config/theme";
import InputSpinner from 'react-bootstrap-input-spinner';

import { Row, Card, Form, Col, Table } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SDRA = () => {
    const history = useHistory();
    const [pao2, setPao2] = useState(80);
    const [fio2, setFio2] = useState(60)
    const setFio2Callback = useCallback(value => setFio2(value), [setFio2]);
    const setPao2Callback = useCallback(value => setPao2(value), [setPao2]);
    const pafi = useMemo(() => Number((pao2 / (fio2 / 100)).toFixed(2)), [pao2, fio2])
    const sdra = useMemo(() => {
        if (pafi === undefined)
            return "";

        if (pafi > 300) {
            return "Oxigenação apropriada"
        } else if (pafi > 200 && pafi <= 300) {
            return "Leve"
        } else if (pafi > 100 && pafi <= 200) {
            return "Moderada";
        } else {
            return "Grave";
        }
    }, [pafi])
    const back = () => {
        history.goBack();
    }
    return (
        <>
            <PageTitle backEvent={back} title="P/F e SDRA" />
            <Row>
                <Col xs={12} className="mb-1">
                    <Card >
                        <Card.Body >
                            <Form>
                                <Form.Row>
                                    <Form.Group sm={12} lg={4} className="offset-lg-2" as={Col} controlId="formpa">
                                        <Form.Label>PaO2 (mmHg)</Form.Label>
                                        <InputSpinner
                                            type={'int'}
                                            precision={0}
                                            variant={'dark'}
                                            max={99999}
                                            min={1}
                                            step={1}
                                            value={pao2}
                                            onChange={setPao2Callback}
                                        />
                                    </Form.Group>
                                    <Form.Group sm={12} lg={4} as={Col} controlId="formfi">
                                        <Form.Label>FiO2 (%)</Form.Label>
                                        <InputSpinner
                                            type={'int'}
                                            precision={0}
                                            variant={'dark'}
                                            max={100}
                                            min={21}
                                            step={1}
                                            value={fio2}
                                            onChange={setFio2Callback}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                            {
                                <Row>
                                    <Col className="text-center">
                                        <Card style={{ alignItems: 'center', borderWidth: 1, borderColor: theme.secondary }}>
                                            <Card.Body className="pr-1 pl-1">
                                                <span color={theme.secondary} className="h6">Relação PaO2/FiO2:</span>
                                                <div style={{ color: theme.primary, fontSize: 25, fontWeight: "bold" }}>
                                                    {pafi}
                                                </div>
                                                <span color={theme.secondary} className="h6">Classificação SDRA:</span>
                                                <div style={{ color: theme.primary, fontSize: 20, fontWeight: "bold" }}>
                                                    {sdra}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="mb-1">
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon icon="info-circle" /> Saiba mais
                        </Card.Header>
                        <Card.Body >
                            <p>
                                A relação PaO2/FiO2 (também chamada relação P/F) é útil para avaliar o quanto de O2 ofertado está sendo transferido a corrente sanguínea e suas hemoglobinas. Isso ajuda a determinar se está havendo hipoxemia refratária à oxigenoterapia instituída, sendo fundamental para seu ajuste apropriado. Assim, temos que:
                            </p>
                            <p>
                                FiO2 oscila, à pressão do nível do mar, entre 21% a 100%, sendo que estudos preconizam máximo de 60% para evitar danos pulmonares, mas na prática a FiO2 ideal é a mínima necessária para sustentar uma PaO2 em valores adequados para boa oxigenação (geralmente acima de 60 mmHg), o que pode, de maneira transitória, requerer até mesmo valores acima de 60.
                            </p>
                            <p>
                                PaO2 determina a concentração de O2 no sangue, em que sua faixa de normalidade é: 60 a 100 mmHg (de hipóxia à hiperóxia). Contudo, embora 60 seja um parâmetro objetivo de hipoxemia, é possível ainda que tendo uma PaO2 de 80, 90, seja taxado de hipoxemia grave. Basta que seja observada qual a FiO2 ofertada. E aí que entra a importância dessa relação, onde:
                            </p>
                            <Table size="sm" hover bordered responsive>
                                <thead>
                                    <tr>
                                        <th>P/F</th>
                                        <th>Classificação SDRA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {'> 300'}
                                        </td>
                                        <td>Oxigenação apropriada</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {'> 200 e ≤ 300'}
                                        </td>
                                        <td>Leve</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {'> 100 e ≤ 200'}
                                        </td>
                                        <td>Moderada</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {'≤ 100'}
                                        </td>
                                        <td>Grave</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default SDRA
