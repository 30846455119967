
const getTotalDose = (dose: number,  weight: number): number => {
    //return Number((dose * weight).toFixed(2));
    return (dose * weight);
}

const getTotalReverseDose = (dose: number, concentration: number, weight: number): number => {
    //return Number(((dose / concentration) * weight).toFixed(2));
    return (dose / concentration) * weight;
}

export {
    getTotalDose,
    getTotalReverseDose,
}