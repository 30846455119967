import React, { memo } from 'react'
import { Card, Table } from 'react-bootstrap'

const MVGuidance = () => {
    return (
        // bg="dark" text="light"
        <Card className="mb-2">
            <Card.Body>
                <p>
                    A Ventilação Mecânica (VM) é uma medida de suporte ventilatório aos pacientes com insuficiência respiratória, seja hipoxêmica (alterações de troca gasosa) ou hipercápnica (incapacidade de ventilação adequada).
                    </p>
                {/* <Ad /> */}
                <div>
                    Ao colocar o paciente em VM, é necessário compreender alguns aspectos prévios, como:
                        <ul>
                        <li>Idade, sexo e altura corporal: determinam o tamanho estimado do pulmão do paciente através do Peso Predito. Peso predito = 50 + 0,91x(Altura – 152,4) para homens, 45,5 + 0,91x(Altura – 152,4) para mulheres;</li>
                        <li>Doenças prévias: avaliar se existem doenças anteriores que possam interferir na ventilação, como asma, DPOC, fibrose pulmonar, entre outras;</li>
                        <li>Motivo que o levou à VM: insuficiência por rebaixamento do nível de consciência (perda de drive), obstrução mecânica (queda de língua, incapacidade de tosse ou disfagia por lesão neurológica), ou alteração na relação V/Q (doenças alveolares que afetem a troca gasosa apropriada).</li>
                    </ul>
                </div>
                <p>
                    Em cima dessas questões, planeja-se o modo ventilatório que será imposto à VM do paciente. É bom saber que os estudos não mostraram vantagens significativas de um para outro, mas que há algumas situações especiais em que os pacientes poderiam se beneficiar mais com uma do que outra modalidade. Portanto, quem avaliará o melhor método a ser utilizado será o profissional que o acompanhar, levando em conta, além do que foi dito acima, sua experiência, o ventilador utilizado, e as metas terapêuticas estabelecidas pela equipe.
                    </p>
                <div>
                    Existem atualmente diversas modalidades. Trazemos aqui, portanto, as principais utilizadas:
                        <ul>
                        <li>
                            Pressure Controlled Ventilation (PCV): modo de ventilação em que se mantém pressão nas vias aéreas predeterminados durante inspiração. Assim, nele ajustamos: Pressão inspiratória (Pinsp), PEEP, Fração Inspirada de O2 (FiO2). Para tal, o fluxo e o volume corrente (VT) são livres: variam conforme características do indivíduo a ser ventilado. Se colocado em modo assistido, permite paciente participar da ventilação – sua maior vantagem. Pode ser assistido ou até mesmo controlado, e isso depende do modo de disparo: se assistido, pode ser à fluxo ou à pressão; se controlado: feito à tempo. A ciclagem pode ocorrer à pressão ou à tempo.
                            Parâmetros sugeridos iniciais:
                                <ul>
                                <li>
                                    Pinsp: Até 40 cmH2O (Ppico segura);
                                    </li>
                                <li>
                                    PEEP: Mínimo de 5 cmH2O (fisiológico), máximo de 25;
                                    </li>
                                <li>
                                    FiO2: mínimo de 21%, máximo 100%. Estudos recomendam ser menor que 60% para evitar toxicidade pulmonar. Na prática: meta de mínimo necessário para sustentar PaO2 {'>'} 60mmHg, e SO2: 90%! Seguir tabela FiO2-PEEP.
                                    </li>
                            </ul>
                        </li>
                        <li>
                            2.	Volume Controlled Ventilation (VCV): modo de ventilação que garante volume corrente (VT), garantido através de um fluxo constante predeterminado. Tem a desvantagem de não se controlar a Pressão de Pico (Ppico), o que pode fazer barotrauma (valor proporcional ao tempo que o pulmão fica exposto a determinado valor de fluxo objetivando determinado VT). Por ter valores mais rígidos, não permite ou permite muito pouco a participação do paciente na ventilação, mas é útil quando se deseja conhecer o pulmão do paciente, pois permite calcular sua resistência (R = (Ppico-Pplatô)/Fluxo) e complacência (C = VT/(Ppico-Pplatô)).
                            Parâmetros sugeridos iniciais:
                        <ul>
                                <li>
                                    VT: 6 a 10 mL/kg de peso predito. Pode fazer menor volume (se SDRA, por exemplo).
                            </li>
                                <li>
                                    FR: Pode ser qualquer valor. O fisiológico gira entre 12 a 20 ipm. Pode ser até 35. Ideal definir conforme PaO2 e SO2, deixando-os maior que 60mmHg ou 90% (respectivamente);
                            </li>
                                <li>
                                    Fluxo: 40 a 60 L/min;
                            </li>
                            </ul>
                        </li>
                        <li>
                            Pressure Support Ventilation (PSV): modalidade criada para desmame da VM, onde o paciente controla sua ventilação, tendo o ventilador como um meio de suporte caso o mesmo não alcance os parâmetros mínimos preestabelecidos. Basicamente, se coloca uma Ppico para ser alcançada, com ciclagem a fluxo. O Tinsp e Texp são definidos pelo paciente. Estabelece também uma Pressão de Suporte (Psuporte), que determina a entrega de um VT proporcional ao esforço do paciente, e suas características ventilatórias. A ciclagem é feita com base ao fluxo.
                            Parâmetros iniciais:
                        <ul>
                                <li>
                                    Fluxo para ciclagem: 25 a 40% do Pico de Fluxo Inspiratório;
                            </li>
                                <li>
                                    Psuporte: conforme indicação. Se desmame da VM, fazer valores cada vez menores, mas abaixo de 15 cmH2O.
                            </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div>
                    Ideal também estabelecer valores de segurança, afinal podemos ver que as modalidades não controlam todos os parâmetros, o que pode levar a um barotrauma ou volutrauma, ou até mesmo toxicidade, como no caso da FiO2. Para isso, existe a chamada VM protetora, na qual se estabelecem limites os quais não são ultrapassados para segurança do paciente.
                    Parâmetros de segurança:
                        <ul>
                        <li>
                            {'Ppico < 40 cmH2O;'}
                        </li>
                        <li>
                            {'Pplatô < 30 cmH2O;'}
                        </li>
                        <li>
                            VT, FiO2 e PEEP conforme necessidade e modo ventilatório.
                             </li>
                    </ul>
                </div>
                <span className="text-danger-light">PEEP baixa/FiO2 alta</span>
                <Table size="sm" variant="" bordered responsive>
                    <tbody>
                        <tr>
                            <td>
                                FiO2
                                </td>
                            <td>0.3</td><td>0.4</td><td>0.4</td><td>0.5</td><td>0.5</td><td>0.6</td><td>0.7</td><td>0.7</td>
                        </tr>

                        <tr>
                            <td>
                                PEEP
                                </td>
                            <td>5</td><td>5</td><td>8</td><td>8</td><td>10</td><td>10</td><td>10</td><td>12</td>
                        </tr>

                    </tbody>
                </Table>
                <Table size="sm" variant="" bordered responsive>
                    <tbody>
                        <tr>
                            <td>
                                FiO2
                                </td>
                            <td>0.7</td><td>0.8</td><td>0.9</td><td>0.9</td><td>0.9</td><td>1.0</td>
                        </tr>

                        <tr>
                            <td>
                                PEEP
                                </td>
                            <td>14</td><td>14</td><td>14</td><td>16</td><td>18</td><td>18-24</td>
                        </tr>

                    </tbody>
                </Table>
                <span className="text-danger-light">PEEP alta/FiO2 baixa</span>
                <Table size="sm" variant="" bordered responsive>
                    <tbody>
                        <tr>
                            <td>
                                FiO2
                                </td>
                            <td>0.3</td><td>0.3</td><td>0.3</td><td>0.3</td><td>0.3</td><td>0.4</td><td>0.4</td><td>0.5</td>
                        </tr>

                        <tr>
                            <td>
                                PEEP
                                </td>
                            <td>5</td><td>8</td><td>10</td><td>12</td><td>14</td><td>14</td><td>16</td><td>16</td>
                        </tr>

                    </tbody>
                </Table>
                <Table size="sm" variant="" bordered responsive>
                    <tbody>
                        <tr>
                            <td>
                                FiO2
                                </td>
                            <td>0.5</td><td>0.5-0.8</td><td>0.8</td><td>0.9</td><td>1.0</td><td>1.0</td>
                        </tr>

                        <tr>
                            <td>
                                PEEP
                                </td>
                            <td>18</td><td>20</td><td>22</td><td>22</td><td>22</td><td>24</td>
                        </tr>

                    </tbody>
                </Table>
                <span>Referências:</span>
                <ul>
                    <li>
                        Manual de Medicina Intensiva da Associação de Medicina Intensiva Brasileira (AMIB). Atheneu, 2014
                        </li>
                    <li>
                        Medicina Intensiva, abordagem prática. HCFMUSP. 4ª ed, Manole, 2020
                        </li>
                </ul>
            </Card.Body>
        </Card>
    )
}

export default memo(MVGuidance);
