import React, { useCallback, useState } from 'react';
import ContactService from '../../modules/user/services/api/ContactService';
import Contact from '../../modules/user/models/Contact'
import { Button, Card, Modal } from 'react-bootstrap';
import ContactForm from '../../modules/user/components/ContactForm';
import '../../config/styles/common.scss'
import SectionTitle from '../../shared/components/SectionTitle';

const ContactPage = () => {
    const [posting, setPosting] = useState<boolean>(false)
    const [message, setMessage] = useState<any>({
        show: false,
        title: '',
        text: '',
    })
    const postContact = useCallback(
        async (contact: Contact, captcha: string | null): Promise<boolean> => {
            let sent: boolean = false;
            try {
                setPosting(true);
                await new ContactService().postContact(contact, captcha);
                setMessage({ show: true, title: 'Sucesso', text: 'Mensagem enviada com sucesso.' })
                sent = true;
            } catch (e) {
                if (e.response.status === 500) {
                    setMessage({ show: true, title: 'Erro', text: e.response.data + ' Caso o erro persita entre em contato conosco via nossas redes sociais.' });
                } else {
                    setMessage({ show: true, title: 'Erro', text: e.response.data});
                }

            } finally {
                setPosting(false);
            }
            return sent;
        },
        []
    );

    const hideModal = () => setMessage({ show: false, message: "" });

    return (
        <>
            <SectionTitle title="Formulário de contato" />
            <Card className="mb-2">
                <Card.Body>
                    <ContactForm onClickSend={postContact} posting={posting}></ContactForm>
                </Card.Body>
            </Card>
            <Modal show={message.show} onHide={hideModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{message.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message.text}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideModal}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ContactPage;
