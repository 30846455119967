import * as drugsData from "../../../../../config/data/";

const getRapidSequenceDrug = (id) => {
    return drugsData.rapidSequence.find(drug => drug.key === Number(id))
}
const getVasoactiveDrug = (id) => {
    return drugsData.vasoactive.find(drug => drug.key === Number(id))
}
const getMaintenanceDrug = (id) => {
    return drugsData.maintenance.find(drug => drug.key === Number(id))
}

export {
    getRapidSequenceDrug,
    getVasoactiveDrug,
    getMaintenanceDrug,
}