import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { references } from "../../../../config/data/rapidSequence";
import { Row, Col, Card, Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DrugInfoCard from '../DrugInfoCard';
import InputSpinner from 'react-bootstrap-input-spinner';
import theme from "../../../../config/theme";
import { getTotalDose, getTotalReverseDose } from "../../services/calculator/rapidSequence"

const RapidSequence = (props: any) => {
    let history = useHistory();
    const [drug] = useState(props.drug);

    // States
    const [weight, setweight] = useState<number | undefined>(80);
    const [dose, setDose] = useState(drug?.doseMax);
    const [concentration, setConcentration] = useState(drug?.concentrations[0].value);

    // Callbacks
    const setDoseCallBack = useCallback(value => setDose(value), [setDose]);
    const setWeightCallBack = useCallback(
        (values: any) => setweight(values.floatValue === undefined ? undefined : Number(values.floatValue)),
        [setweight]
    );
    const setConcetrationCallBack = useCallback(event => setConcentration(event.target.value), [setConcentration]);

    // Effects
    useEffect(() => {
        if (!props.drug) {
            history.push("/");
        }
    });

    useEffect(() => {
        if (drug) {
            setDose(drug?.doseMax);
            setConcentration(drug?.concentrations[0].value);
        }
    }, [drug])

    // Memos
    const doseIntervalText = useMemo(
        () => <Form.Label>Dose ({drug?.doseMin + " - " + drug?.doseMax + drug?.doseUnit})</Form.Label>,
        [drug]
    );
    const resultDose = useMemo(
        () => <div id="dose-result" style={{ color: theme.primary, fontSize: 25, fontWeight: "bold" }}>
            {Number(getTotalDose(dose, weight!).toFixed(2))}
            {drug?.doseUnit + (drug?.timeUnit ? '/' + drug?.timeUnit : '')}
        </div>,
        [dose, weight, drug]
    );

    const resultReverseDose = useMemo(
        () => <div id="dose-reverse-result" style={{ color: theme.primary, fontSize: 25, fontWeight: "bold" }}>
            {Number(getTotalReverseDose(dose, concentration, weight!).toFixed(2))}
            {drug.reverseDoseUnit}
        </div>,
        [dose, concentration, weight, drug]
    )

    return (
        <>
            {
                drug &&
                <>
                    <Row>
                        <Col xs={12} className="pl-1 pr-1 pb-2">
                            <Card >
                                <Card.Body >
                                    <div className="type-container">
                                        <FontAwesomeIcon icon="chevron-right" className="type-icon" />
                                        <span id="page-title-subtitle" className="type-text">
                                            {drug?.type}
                                        </span>
                                    </div>
                                    <Form>
                                        <Form.Row>
                                            <Form.Group sm={12} md={4} as={Col} controlId="formDose">
                                                {doseIntervalText}
                                                {
                                                    drug !== undefined && dose !== undefined &&
                                                    <InputSpinner
                                                        variant="dark"
                                                        type={'real'}
                                                        precision={2}
                                                        max={drug?.doseMax}
                                                        min={drug?.doseMin}
                                                        step={drug?.doseStep}
                                                        value={dose}
                                                        onChange={setDoseCallBack}
                                                    />
                                                }
                                            </Form.Group>
                                            <Form.Group sm={12} md={4} as={Col} controlId="formConcentrarion">
                                                <Form.Label>Concentração</Form.Label>
                                                {
                                                    drug?.concentrations.length > 1 &&
                                                    <Form.Control as="select" value={concentration} onChange={setConcetrationCallBack}>
                                                        {
                                                            drug?.concentrations.map((concentration: any) =>
                                                                <option key={concentration.value} value={concentration.value}>{concentration.label}</option>
                                                            )
                                                        }
                                                    </Form.Control>
                                                }
                                                {
                                                    drug?.concentrations.length === 1 &&
                                                    <Form.Control disabled defaultValue={drug?.concentrations.find((c: any) => c.value === concentration)?.label} />
                                                }
                                            </Form.Group>
                                            <Form.Group sm={12} md={4} as={Col} controlId="formWeight">
                                                <Form.Label>Peso do paciente (Kg)</Form.Label>
                                                <NumberFormat className="form-control" format="###" value={weight} onValueChange={setWeightCallBack} />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>
                                    {
                                        weight !== undefined && Number(weight) !== 0 && dose !== undefined && dose >= drug?.doseMin && dose <= drug?.doseMax &&
                                        <Row>
                                            <Col className="text-center">
                                                <Card style={{ alignItems: 'center', borderWidth: 1, borderColor: theme.secondary }}>
                                                    <Card.Body>
                                                        <span color={theme.secondary} className="h6">Administrar:</span>
                                                        {resultDose}
                                                        <FontAwesomeIcon icon="exchange-alt" size="lg" color={theme.secondary} />
                                                        {resultReverseDose}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {
                            drug && drug.presentations && drug.presentations.length > 0 &&
                            <DrugInfoCard title={drug.presentations.length > 1 ? 'Apresentações' : 'Apresentação'} list={drug.presentations} iconName={'vial'} />
                        }
                        {
                            drug && drug.characteristics && drug.characteristics.length > 0 &&
                            <DrugInfoCard title={drug.characteristics.length > 1 ? 'Características' : 'Característica'} list={drug.characteristics} iconName={'star'} />
                        }
                        {
                            drug && drug.indications && drug.indications.length > 0 &&
                            <DrugInfoCard title={drug.indications.length > 1 ? 'Indicações' : 'Indicação'} list={drug.indications} iconName={'check'} />
                        }
                        {
                            drug && drug.contraindications && drug.contraindications.length > 0 &&
                            <DrugInfoCard title={drug.contraindications.length > 1 ? 'Contraindicações' : 'Contraindicação'} list={drug.contraindications} iconName={'times'} />
                        }
                        {
                            drug && drug.usage && drug.usage.length > 0 &&
                            <DrugInfoCard title={'Como usar'} list={drug.usage} iconName={'hand-paper'} />
                        }
                        {
                            drug && references && references.length > 0 &&
                            <DrugInfoCard
                                title={references.length > 0 ? 'Referências' : 'Referência'} list={references} iconName={'star-of-life'} itemFontSize={11} />
                        }
                    </Row>
                </>
            }
        </>
    )
}

export default RapidSequence;
