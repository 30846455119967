
const getTotalDose = (
    flow: number, weight: number, time: number,
    ampouleDose: number, ampouleVolume: number, ampouleDosePerMl: number,
    solutionVolume: number, multiplier: number
): number => {
    return flow /
        (
            (weight ? weight : 1) * time /
            (
                (ampouleDose * (ampouleVolume / ampouleDosePerMl) * multiplier) /
                (solutionVolume || (ampouleVolume * multiplier))
            )
        );
}

const getTotalFlowRate = (
    dose: number, weight: number, time: number,
    ampouleDose: number, ampouleVolume: number, ampouleDosePerMl: number,
    solutionVolume: number, multiplier: number
) => {
    return (dose * (weight ? weight : 1) * time) /
        (
            (ampouleDose * (ampouleVolume / ampouleDosePerMl) * multiplier) /
            (solutionVolume || (ampouleVolume * multiplier))
        );
}

export {
    getTotalDose,
    getTotalFlowRate
}