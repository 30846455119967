import { AnyAction } from "redux";
import { 
    USER_PREFS_LANG_UPDATE, 
    USER_PREFS_IPHONE_ALERT_UPDATE, 
    USER_PREFS_MINIMIZED_SCROLL_TIP 
} from "./actions";

export interface IUserState {
    preferences: {
        iphoneAlertShortcut: boolean;
        lang: string;
        minimizedScrollTip: boolean;
        theme: "light" | "dark";
    },
}

export const intialState: IUserState = {
    preferences: {
        iphoneAlertShortcut: true,
        lang: 'pt-BR',
        minimizedScrollTip: false,
        theme: "light",
    }
};

export default function userReducer(state: IUserState = intialState, action: AnyAction): IUserState {
    const { type, payload } = action;
    switch (type) {
        case USER_PREFS_IPHONE_ALERT_UPDATE:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    iphoneAlertShortcut: payload.iphoneAlertShortcut,
                }
            }
        case USER_PREFS_LANG_UPDATE:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    lang: payload.lang,
                }
            }
        case USER_PREFS_MINIMIZED_SCROLL_TIP:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    minimizedScrollTip: payload.minimizedScrollTip
                }
            }
        default:
            return state;
    }
}
