import React from 'react'

import { ListGroup, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../shared/components/PageTitle'

const Calculators = (props: any) => {
    let history = useHistory();
    const back = () => {
        history.goBack();
    }
    return (
        <>
            <PageTitle backEvent={back} title="Ventilação mecânica" ></PageTitle>
            <Row>
                <Col xs={12} className="mb-1">
                    {/* list-group-mr */}
                    <ListGroup className="">
                        <LinkContainer to={props.match.path + "/ideal-weight"}>
                            <ListGroup.Item action>
                                Peso predito
                            </ListGroup.Item>
                        </LinkContainer>
                        <LinkContainer to={props.match.path + "/sdra"}>
                            <ListGroup.Item action>
                                {'P/F e SDRA'}
                            </ListGroup.Item>
                        </LinkContainer>
                    </ListGroup>
                </Col>
            </Row>
        </>
    )
}

export default Calculators