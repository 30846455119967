import React, { useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Contact, { SubjectType } from '../../models/Contact';
import './index.scss'
import validator from 'validator';
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../../config/env"

interface ContactFormProps {
  onClickSend: (contact: Contact, captcha: string | null) => Promise<boolean>,
  posting: boolean
}

const ContactForm = (props: ContactFormProps) => {
  const refCaptcha = useRef<ReCAPTCHA | null>(null)
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [error, setError] = useState<any>({
    show: false,
    message: "",
  });
  const [contact, setContact] = useState<Contact>({
    name: "",
    email: "",
    subject: SubjectType.PROBLEM,
    message: "",
  });

  const isFormValid = (): any => {
    let filled = Object.values(contact).reduce((acc, value) => acc && (value ? !validator.isEmpty(value, { ignore_whitespace: true }) : value), true);
    if (!filled) {
      return { show: true, message: "Por favor, preencha todos os campos." }
    }
    // nome
    if (!validator.isLength(contact.name, { min: 2, max: 100 })) {
      return { show: true, message: "Campo nome deve possuir entre 2 e 100 caracteres." }
    }
    // e-mail
    if (!validator.isEmail(contact.email)) {
      return { show: true, message: "Campo e-mail inválido." }
    }
    if (!validator.isLength(contact.email, { min: 3, max: 100 })) {
      return { show: true, message: "Campo e-mail deve possuir entre 3 e 100 caracteres." }
    }
    // mensagem
    if (!validator.isLength(contact.message, { min: 2, max: 1000 })) {
      return { show: true, message: "Campo mensagem deve possuir entre 2 e 1000 caracteres." }
    }

    return {
      show: false,
      message: "",
    }
  }

  const onClickSend = async (e: any) => {
    e.preventDefault();
    let err = isFormValid()
    setError(err);
    if (!err.show) {
      let c: Contact = { ...contact };
      c.name = c.name.trim();
      c.email = c.email.trim();
      c.message = c.message.trim();
      let sent = await props.onClickSend(contact, captcha);
      if (sent) {
        clearForm();
      } else {
        setContact(c);
      }
      refCaptcha!.current!.reset();
    }
  }

  const clearForm = () => {
    setContact((prev: Contact) => ({
      ...prev,
      name: "",
      email: "",
      message: "",
    }));
  }

  const onInputChange = (e: any) => {
    const value = e.target.value;
    setContact({ ...contact, [e.target.name]: value });
  }

  const onCaptchaChange = (value: string | null) => {
    if (value === null) {
      setCaptcha(null);
    } else {
      setCaptcha(value);
    }
  }

  const hideModal = () => setError({ show: false, message: "" });

  return (
        <>
            <Form>
                <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control name="name" type="text" onChange={onInputChange} placeholder="Digite seu nome"
                        value={contact.name}
                    ></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label >E-mail</Form.Label>
                    <Form.Control name="email" type="email" onChange={onInputChange} placeholder="seu_email@exemplo.com"
                        value={contact.email}
                    ></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Assunto</Form.Label>
                    <Form.Control name="subject" as="select" onChange={onInputChange} value={contact.subject}>
                        {
                            Object.values(SubjectType).map(st => <option key={st} value={st}>{st}</option>)
                        }
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Mensagem</Form.Label>
                    <Form.Control name="message" as="textarea" rows={4} onChange={onInputChange}
                        value={contact.message}
                    ></Form.Control>
                </Form.Group>
                <div className="mb-2">
                    <ReCAPTCHA
                        ref={refCaptcha}
                        sitekey={config.reCaptchaSecret}
                        onChange={onCaptchaChange}
                    />
                </div>
                <Button
                    className="button-send"
                    onClick={onClickSend}
                    disabled={props.posting || (captcha === null)}
                >
                    {
                        props.posting ?
                            <>
                                Enviar <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
                            </>
                            :
                            <>
                                Enviar <FontAwesomeIcon icon="paper-plane"></FontAwesomeIcon>
                            </>
                    }

                </Button>
            </Form>
            <Modal show={error.show} onHide={hideModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Campos obrigatórios</Modal.Title>
                </Modal.Header>
                <Modal.Body>{error.message}</Modal.Body>
                <Modal.Footer>
                    <Button className="button-send" onClick={hideModal}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ContactForm;
