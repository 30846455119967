import React, {memo} from 'react'
import { Card, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../../config/theme'

interface IDrugInfoCardProps {
    title: string,
    list: Array<any>,
    iconName: any,
    itemFontSize?: any,
}

const DrugInfoCard = (props: IDrugInfoCardProps) => {
    return (
        <Col lg={6} xs={12} className="pl-1 pr-1 pb-2">
            <Card id="drug-info-card" className="h-100">
                <Card.Header>
                <FontAwesomeIcon icon="chevron-right" className="type-icon" /><b>{props.title}</b>
                </Card.Header>
                <Card.Body>
                    {
                        props.list.map((item, index) =>
                            <div key={index}>
                                <FontAwesomeIcon color={theme.secondary} style={{ fontSize: props.itemFontSize ? props.itemFontSize : "14" }} icon={props.iconName} />
                                <span style={{ fontSize: props.itemFontSize ? props.itemFontSize : null! }}>{' ' + item}</span>
                            </div>)
                    }
                </Card.Body>
            </Card>
        </Col>
    )
}

export default memo(DrugInfoCard);
