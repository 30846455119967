const baseUrl = "https://us-central1-mr-critic-277220.cloudfunctions.net";
const reCaptchaSecret = "6LfQBj8aAAAAANWeUR4D-728kmeEaL-IEU-tCzH0";

const local = {
    baseUrl: "http://localhost:8080",
    reCaptchaSecret
};

const dev = {
    baseUrl,
    reCaptchaSecret
};

const staging = {
    firebaseConfig: {
        apiKey: "AIzaSyDG1JlKPF1etg4x9L1IFk-0X2-TuGe2I58",
        authDomain: "mr-critic-staging.firebaseapp.com",
        projectId: "mr-critic-staging",
        storageBucket: "mr-critic-staging.appspot.com",
        messagingSenderId: "324201449698",
        appId: "1:324201449698:web:8da101c6b1281dcd4836bb"
    },
    baseUrl,
    reCaptchaSecret
};

const prod = {
    firebaseConfig: {
        apiKey: "AIzaSyBj-FQ-ncjq9H-LZb0UyYVbx6h2CVpYPxQ",
        authDomain: "mr-critic-277220.firebaseapp.com",
        databaseURL: "https://mr-critic-277220.firebaseio.com",
        projectId: "mr-critic-277220",
        storageBucket: "mr-critic-277220.appspot.com",
        messagingSenderId: "888396354747",
        appId: "1:888396354747:web:c89bc9bd1a1f48e931059e",
        measurementId: "G-HDJQGNRZH6"
    },
    baseUrl,
    reCaptchaSecret
};

const getConfig = () => {
    switch (process.env.REACT_APP_BUILD_TARGET) {
        case 'production':
            return prod;
        case 'staging':
            return staging;
        case 'development':
            return dev;
        case 'local':
        default:
            return local;
    }
}

const config: any = getConfig();

export default config;
