import React, { useCallback, useState, useEffect } from 'react';

import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { SHARE_SQUARE } from '../../assets/images';
import { isIOS } from '../../shared/utils/platformUtils';
import theme from '../../config/theme';
import { useWindowWidth } from '@react-hook/window-size';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import { updatePrefsIphoneAlert, updatePrefsMinimizedScrollTip } from '../../modules/user/store/actions';
import SectionTitle from "../../shared/components/SectionTitle";

const Home = () => {
    const [contentScolled, setContentScrolled] = useState<boolean>(false);
    const [contentOverflown, setContentOverflown] = useState<boolean>(false);
    const [defaultItemWidth, setDefaultItemWidth] = useState<number | undefined>(undefined);
    const mvRef = React.createRef<HTMLDivElement>();
    const firstItemRef = React.createRef<HTMLDivElement>();

    const dispatch = useDispatch();
    const userPrefs = useSelector((state: RootState) => state.user.preferences);

    const width = useWindowWidth();
    const ios = isIOS(navigator);

    const onCloseAlertCallback = useCallback(
        () => dispatch(updatePrefsIphoneAlert(false)),
        [dispatch],
    );

    useEffect(() => {
        if (!contentOverflown) {
            const hasOverflowingChildren = mvRef.current?.offsetHeight! < mvRef.current?.scrollHeight! || mvRef.current?.offsetWidth! < mvRef.current?.scrollWidth!;
            if (hasOverflowingChildren) {
                setContentOverflown(true);
                setDefaultItemWidth(firstItemRef.current?.clientWidth);
            }
        }
    }, [contentOverflown, mvRef, firstItemRef]);

    const setMinimizeScrollTip = useCallback(
        (minimize: boolean) => dispatch(updatePrefsMinimizedScrollTip(minimize)),
        [dispatch]
    );

    const onContentScroll = useCallback(
        () => {
            if (!contentScolled) {
                setContentScrolled(true);
                setMinimizeScrollTip(true);
            }
        },
        [contentScolled, setMinimizeScrollTip]
    );

    const minimizeTip = () => contentScolled || userPrefs.minimizedScrollTip;

    // const section = (name: string) => (
    //     <div className="section-header">
    //         <span className={`section-header-text--${userPrefs.theme}`}>{name}</span>
    //         <hr className="section-header-rule" />
    //     </div>
    // );

    return (
        <>
            <div className="section">
                <SectionTitle title="Intubação Orotraqueal" />
                <div className="section-content">
                    <div className="section-item" ref={firstItemRef}>
                        <Link to="/orotracheal-intubation/drugs" className="section-item-headar-link">
                            <FontAwesomeIcon icon="tint" className="home-card-icon" /> <span className="section-item-headar-text">Drogas</span>
                        </Link>
                        <div className="section-item-body">
                            Calcule doses de 8 tipos de drogas e veja suas características, indicações e muito mais.
                        </div>
                        <div>
                            <Link to="/orotracheal-intubation/drugs" className="section-item-footer-link">
                                <span className="section-item-footer-text">Selecione </span><FontAwesomeIcon icon="arrow-right" className="home-card-icon" />
                            </Link>
                        </div>
                    </div>
                    <div className="section-item">
                        <Link to="/orotracheal-intubation/guidance" className="section-item-headar-link">
                            <FontAwesomeIcon icon="book-open" className="home-card-icon" /> <span className="section-item-headar-text">Orientações</span>
                        </Link>
                        <div className="section-item-body">
                            Veja um resumo das principais informações que você precisa saber sobre IOT.
                    </div>
                        <div>
                            <Link to="/orotracheal-intubation/guidance" className="section-item-footer-link">
                                <span className="section-item-footer-text">Saiba mais </span><FontAwesomeIcon icon="arrow-right" className="home-card-icon" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <SectionTitle title="Ventilação Mecânica" />
                <div id="vmcontent" className="section-content" ref={mvRef} onScroll={onContentScroll}>
                    <div className="section-item" style={{ minWidth: (contentOverflown ? defaultItemWidth : undefined) }}>
                        <Link to="/mechanical-ventilation/drugs" className="section-item-headar-link">
                            <FontAwesomeIcon icon="tint" className="home-card-icon" /> <span className="section-item-headar-text">Drogas</span>
                        </Link>
                        <div className="section-item-body">
                            Calcule doses de 8 tipos de drogas e veja suas características, indicações e muito mais.
                        </div>
                        <div>
                            <Link to="/mechanical-ventilation/drugs" className="section-item-footer-link">
                                <span className="section-item-footer-text">Selecione </span><FontAwesomeIcon icon="arrow-right" className="home-card-icon" />
                            </Link>
                        </div>
                    </div>
                    <div className="section-item" style={{ minWidth: (contentOverflown ? defaultItemWidth : undefined) }}>
                        <Link to="/mechanical-ventilation/calculators" className="section-item-headar-link">
                            <FontAwesomeIcon icon="calculator" className="home-card-icon" /> <span className="section-item-headar-text">Calculadoras</span>
                        </Link>
                        <div className="section-item-body">
                            Calcule peso predito e relação P/F e classificação SDRA.
                            </div>
                        <div>
                            <Link to="/mechanical-ventilation/calculators" className="section-item-footer-link">
                                <span className="section-item-footer-text">Selecione </span><FontAwesomeIcon icon="arrow-right" className="home-card-icon" />
                            </Link>
                        </div>
                    </div>
                    <div className="section-item" style={{ minWidth: (contentOverflown ? defaultItemWidth : undefined) }}>
                        <Link to="/mechanical-ventilation/guidance" className="section-item-headar-link">
                            <FontAwesomeIcon icon="book-open" className="home-card-icon" /> <span className="section-item-headar-text">Orientações</span>
                        </Link>
                        <div className="section-item-body">
                            Veja um resumo das principais informações que você precisa saber sobre VM incluindo tabela FiO2-PEEP.
                            </div>
                        <div>
                            <Link to="/mechanical-ventilation/guidance" className="section-item-footer-link">
                                <span className="section-item-footer-text">Saiba mais </span><FontAwesomeIcon icon="arrow-right" className="home-card-icon" />
                            </Link>
                        </div>
                    </div>
                </div>
                {
                    width <= 720 &&
                    <div className={minimizeTip() ? "section-item-overflow-hidden" : "section-item-overflow"}>
                        <div className="section-item-overflow-content">
                            <FontAwesomeIcon icon="arrow-left" color={theme.secondary} style={{ paddingRight: 3, height: (minimizeTip() ? 8 : undefined!) }} />
                            {
                                !minimizeTip() &&
                                <FontAwesomeIcon icon="hand-point-up" color={theme.secondary} />
                            }
                            <FontAwesomeIcon icon="arrow-right" color={theme.secondary} style={{ paddingLeft: 3, height: (minimizeTip() ? 8 : undefined!) }} />
                        </div>
                    </div>
                }
            </div>
            <div className="section">
                <SectionTitle title="Hemodinâmica" />
                <div className="section-content">
                    <div className="section-item">
                        <Link to="/hemodynamic/drugs" className="section-item-headar-link">
                            <FontAwesomeIcon icon="tint" className="home-card-icon" /> <span className="section-item-headar-text">Drogas</span>
                        </Link>
                        <div className="section-item-body">
                            Calcule doses de 7 tipos de drogas e veja suas características, indicações e muito mais.
                    </div>
                        <div>
                            <Link to="/hemodynamic/drugs" className="section-item-footer-link">
                                <span className="section-item-footer-text">Selecione </span><FontAwesomeIcon icon="arrow-right" className="home-card-icon" />
                            </Link>
                        </div>
                    </div>
                    <div className="section-item">
                        <Link to="/hemodynamic/guidance" className="section-item-headar-link">
                            <FontAwesomeIcon icon="book-open" className="home-card-icon" /> <span className="section-item-headar-text">Orientações</span>
                        </Link>
                        <div className="section-item-body">
                            Veja um resumo das principais informações que você precisa saber sobre a monitorização hemodinâmica.
                        </div>
                        <div>
                            <Link to="/hemodynamic/guidance" className="section-item-footer-link">
                                <span className="section-item-footer-text">Saiba mais </span><FontAwesomeIcon icon="arrow-right" className="home-card-icon" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {
                ios && userPrefs.iphoneAlertShortcut &&
                <Alert
                    className="mt-2 mb-2"
                    variant={'dark'}
                    dismissible
                    onClose={onCloseAlertCallback}
                >
                    <strong>Adicione um atalho</strong>. Acessando a partir do Safari, clique no ícon <img alt="" src={SHARE_SQUARE}></img>  e em "Adicionar à Tela de Início"
                </Alert>
            }
        </>
    )
}

export default Home
