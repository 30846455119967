import React, { useState, useCallback, useMemo, useEffect } from 'react'

import "./idealWeight.css"

import { Row, Card, Form, Col, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import PageTitle from "../../../shared/components/PageTitle";
import theme from "../../../config/theme";
import InputSpinner from 'react-bootstrap-input-spinner';

const IdealWeight = () => {
    const history = useHistory();
    // States
    const [height, setheight] = useState(1.7);
    const [gender, setgender] = useState('M')
    const [weight, setweight] = useState<number | undefined>();

    // Callbacks
    const setHeightCallBack = useCallback(value => setheight(value), [setheight]);
    const setGenderCallback = useCallback(value => setgender(value), [setgender]);

    const tidalVolume = useMemo(
        () => <div className="text-tital-volume-mr">
            <b>{'Volume Corrente: '}</b>
            {gender === 'M' ? '6 a 8 mL/kg - Início da VM' : '< 6 mL/kg - SARA'}
        </div>,
        [gender]
    );

    const idealVolume = useMemo(
        () => <div>
            <b>{'Volume Ideal: '}</b>
            {gender === 'M' ? (Number((weight! * 6).toFixed(2)) + 'mL - ' + Number((weight! * 8).toFixed(2)) + 'mL') : '< ' + Number((weight! * 6).toFixed(2)) + 'mL'}
        </div>,
        [gender, weight]
    );

    useEffect(() => {
        let result: number = 0;
        if (gender === 'M') {
            result = 50 + 0.91 * ((height * 100) - 152.4)
        } else {
            result = 45.5 + 0.91 * ((height * 100) - 152.4)
        }
        result = Number(result.toFixed(2));
        setweight(result);
    },
        [gender, height]
    )

    const back = () => {
        history.goBack();
    }

    return (
        <>
            <PageTitle backEvent={back} title="Peso Predito" />
            <Row>
                <Col xs={12} className="mb-1">
                    <Card >
                        <Card.Body >
                            <Form>
                                <Form.Row>
                                    <Form.Group sm={12} md={2} className="offset-md-3" as={Col} controlId="formGender">
                                        <Form.Label>Gênero</Form.Label>
                                        <div style={{ width: '100%' }} className="btn-group btn-group-justified" data-toggle="buttons">
                                            <ToggleButtonGroup className="w-100" name="radio" type="radio" value={gender} onChange={setGenderCallback}>
                                                <ToggleButton variant={gender === 'M' ? "dark" : "secondary"} value={'M'}><FontAwesomeIcon icon="mars" size={'lg'} /></ToggleButton>
                                                <ToggleButton variant={gender === 'F' ? "dark" : "secondary"} value={'F'}><FontAwesomeIcon icon="venus" size={'lg'} /></ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </Form.Group>
                                    <Form.Group sm={12} md={4} as={Col} controlId="formWeight">
                                        <Form.Label>Altura {gender === 'M' ? 'do' : 'da'} paciente (m)</Form.Label>
                                        <InputSpinner
                                            type={'real'}
                                            precision={2}
                                            variant={'dark'}
                                            max={2.99}
                                            min={1}
                                            step={0.01}
                                            value={height}
                                            onChange={setHeightCallBack}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                            {
                                !!height &&
                                <Row>
                                    <Col className="text-center">
                                        <Card style={{ alignItems: 'center', borderWidth: 1, borderColor: theme.secondary }}>
                                            <Card.Body className="pr-1 pl-1">
                                                <span color={theme.secondary} className="h6">Peso predito:</span>
                                                <div style={{ color: theme.primary, fontSize: 25, fontWeight: "bold" }}>
                                                    {weight + 'kg'}
                                                </div>
                                                {
                                                    tidalVolume
                                                }
                                                {
                                                    idealVolume
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="mb-1">
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon icon="info-circle" /> Saiba mais
                        </Card.Header>
                        <Card.Body >
                            <p>
                                Saber o volume pulmonar do paciente em questão é fundamental para instituir a terapêutica ventilatória apropriada. E o volume corrente é totalmente dependente do seu volume pulmonar. Com isso, calcular o volume corrente apropriado para determinado peso é fundamental para evitar distender excessivamente o pulmão, o que pode levar a volutrauma.
                            </p>
                            <p>
                                Porém, o peso trazido para a conta não é o peso absoluto, ou atual, do paciente, mas sim, o peso ideal para àquela altura. Isso porque um paciente que ganha ou perde peso não está aumentando ou reduzindo os volumes e massas de seus órgãos em geral (via de regra), mas sim acumulando ou perdendo tecido adiposo. E nesse processo, o pulmão não sofre interferência, mantendo seu volume (e peso) sem grandes oscilações. Assim, podemos dizer que, via de regra, o pulmão de um obeso de 120kg tem volume e proporções idênticas ao pulmão de um desnutrido de 56kg, se ambos tiverem 1,80m de altura, por exemplo.
                            </p>
                            <p>
                                Portanto, o cálculo de volume corrente por kg de peso predito é fundamental para avaliar volume adequado para aquele pulmão.
                            </p>
                            <p>
                                Além disso, ter a faixa de normalidade em mãos para aquele paciente também será útil nos casos extremos: quando precisar impor valores da ventilação menores que o limite inferior, ou maiores.
                            </p>
                            <p>
                                A faixa de normalidade de um VT é de cerca de 6 a 10 mL/kg. Contudo, em casos de SDRA moderados a grave, o pulmão sofre danos estruturais em que reduzem áreas participativas da insuflação e troca gasosas, tornando um volume efetivo menor funcionante, o chamado "baby lung". Com isso, assume-se que o volume efetivo daquele pulmão seja bem menor que o normal, por isso aceitando-se VT menores que 6mL/kg. Por outro lado, doentes com restrições pulmonares, como doenças fibróticas prévias, podem requerer volumes levemente superiores a 10 mL/kg para vencer tal limitação e melhor acomodar o ar nas vias aéreas para possibilitar uma troca apropriada.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default IdealWeight
